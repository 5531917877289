import axios from 'axios';
import { setErrors } from './setErrors';

export const postForm = (formname, url) => data => {
  return axios
    .post(url, data)
    .catch(function (error) {
      if (error.response.status === 422) {
        return setErrors(formname)(error.response.data.errors);
      }
      if (console) {
        console.error('Unexpected submtting form: ', error);
      }
      throw error
    });
};
