import React from 'react'
import { graphql } from 'gatsby'

import Card from './Card'
import ModalVideo from './ModalVideo'


export default props =>
  <ModalVideo {...props.video}>
    {({ toggle }) => 
      <Card
        image={props.image}
        title={props.title}
        subtitle={props.date}
        text={props.intro}
        video={true}
        onClick={toggle}
      />
    } 
  </ModalVideo>



export const customerStoryCardMapping = ({
  image,
  intro,
  ...props
}) => ({
  ...props,
  image: image && image.file && image.file.url,
  intro: intro && intro.childMarkdownRemark && intro.childMarkdownRemark.html,
})



export const fragment = graphql`
  fragment ContentfulCustomerStoryFragment on ContentfulCustomerStory {
    id
    title
    video {
      channel
      url
    }
    image {
      thumbnail: fixed(width: 70, height: 70) {
        src
      }
      file {
        url
      }
    }
    date(formatString: "DD MMM YYYY")
    intro {
      childMarkdownRemark {
        html
      }
    }
  }
`