import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Section from './Section'
import Link from './Link'

import './JobOpeningsSection.scss'

export default ({ entryTitle = null, items = [] }) => {
  return (
    <Section className="JobOpeningsSection py-6" id="openings">
      <Container>
        <Row>
          <Col md={12}>
            <div className="JobOpeningsSection__text m-auto">
              <h2 className="h2 mb-3">Job Openings</h2>
              <p className="mb-6">
                If you’re as excited as we are about supporting small-to-medium
                sized businesses, then we want to hear from you!
              </p>
            </div>
            <div className="JobOpeningsSection__openings">
              {items &&
                items.map((item, index) => (
                  <div className="w-100 mt-3 Job">
                    <p className="first">
                      <h4>
                        <a target="_blank" href={item.externalLink}>
                          {item.jobTitle}
                        </a>
                      </h4>
                    </p>
                    <p>{item.location}</p>
                    <p>{item.department}</p>
                    <p>{item.hours}</p>
                  </div>
                ))}
              {!items && (
                <h6 className="text-center">
                  Thank you for your interest, but at this time, we don't have
                  any job vacancies. <br />
                  Please email through your CV, and we will notify you should a
                  position for which you would
                  <br />
                  be a good match becomes available.
                </h6>
              )}
            </div>
            <div className="JobOpeningsSection__text m-auto">
              {items && (
                <p className="mt-4 mb-6">
                  Can’t find what your looking for? Send your CV to one of the
                  team and we’ll be in touch.
                </p>
              )}
              <Link
                type="button"
                color="cyan"
                href="mailto:careers@myhr.works"
                localePrefix={false}
              >
                Email CV
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export const fragment = graphql`
  fragment ContentfulJobOpeningsSectionFragment on ContentfulJobOpeningsSection {
    entryTitle
    items {
      jobTitle
      externalLink
      location
      department
      hours
    }
  }
`
