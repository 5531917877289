import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import Section from './Section'
import Link from './Link'

import './CareersBannerSection.scss'
import image from '../images/section/careers.png'


export default () =>
  <Section className="CareersBannerSection py-0">
    <Container>
      <Row>
        <Col md={6} className="d-none d-md-block">
          <div className="CareersBannerSection__image">
            <img src={image} alt="HR Staff" />
          </div>
        </Col>
        <Col md={6}>
          <div className="h-100 d-flex">
            <div className="CareersBannerSection__text m-auto">
              <h2 className="h1 mb-2">Careers at MyHR</h2>
              <p>
                Join our team at MyHR and help revolutionise the way HR is delivered to SME business
                all over Australia and New Zealand. We’re always on the lookout for HR visionaries,
                SAAS marketing and sales gurus and dynamic, tech crunching engineers.
              </p>
              <Link
                type="button"
                color="cyan"
                href="/careers"
                localePrefix={true}
              >
                Get in touch
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Section>