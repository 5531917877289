import React from 'react'
import { Container, Badge } from 'reactstrap'

import Section from './Section'

import './MediaEnquirySection.scss'
import Link from './Link'


export default () =>
  <Section className="MediaEnquirySection">
    <Container>
      <div className="MediaEnquirySection__text">
        <Badge color="radical-red" pill className="mb-2">
          Let's talk
        </Badge>
        <h2 className="h1 mb-2">Have a media enquiry?</h2>
        <Link
          type="button"
          color="gray"
          localePrefix={true}
          href="/contact"
          className="mt-2"
        >
          Get in touch
        </Link>
      </div>
    </Container>
  </Section>