import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col, Container } from 'reactstrap'

import Section from './Section'
import RichText from './RichText'
import Link from './Link'

import './FeaturesSection.scss'
import mobileHomepage from '../images/hero/homepage-mobile.jpg'


export default ({
  body = null,
  features = []
}) =>
  <Section id="features" className="FeaturesSection pb-0">
    <Section.Intro className="text-center">
      <h2 className="h3">Free your <span className="text-electric-blue">time</span>.<br className="d-block d-sm-none"/> Free your <span className="text-electric-blue">people</span>.</h2>
      {body && body.json &&
        <RichText json={body.json} />
      }
    </Section.Intro>

    <div className="d-md-none mb-3">
      <img src={mobileHomepage} alt={'HR Software. HR Support.'} />
    </div>
    
    <Container>
      <Row>
        {features.map((feature, index) => {
          const { name, hash } = feature
          const summary = feature.summary && feature.summary.childMarkdownRemark && feature.summary.childMarkdownRemark.html
          const imageUrl = feature.summaryImage && feature.summaryImage.file && feature.summaryImage.file.url

          return (
            <Col md={6} lg={4} className="my-4" key={index} >
              <div className="FeaturesSection__item">
                {imageUrl &&
                  <Link
                    href={`/features${hash ? `#${hash}` : ''}`}
                    localePrefix={true}
                    className="FeaturesSection__item-img"
                  >
                    <img
                      src={imageUrl}
                      alt={name}
                      className={index%2 ? 'order-last' : null}
                    />
                  </Link>
                }
                <div>
                  <div className="h5 mb-2">{name}</div>
                  {summary &&
                    <div
                      dangerouslySetInnerHTML={{ __html: summary }}
                    />
                  }
                  <Link
                    href={`/features${hash ? `#${hash}` : ''}`}
                    localePrefix={true}
                  >
                    Read more »
                  </Link>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
    <div className="FeaturesSection__button bg-gray-triangle">
      <Link
        type="button"
        color="radical-red"
        href="/features"
        localePrefix={true}
      >
        Full feature list
      </Link>
    </div>
  </Section>


export const fragment = graphql`
  fragment ContentfulFeaturesSectionFragment on ContentfulFeaturesSection {
    body {
      json
    }
    features {
      hash
      name
      summary {
        childMarkdownRemark {
          html
        }
      }
      summaryImage {
        file {
          url
        }
      }
    }
  }
`