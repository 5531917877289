import React, { useContext, useState } from 'react'
import { Container, Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { submit as hubspotSubmit } from '../utils/hubspotForm'
import { useLocale } from '../hooks/useLocale'

import './CommunityForm.scss'
import { companySizes } from '../data/companySizes'
import { roles as hubspotRoles } from '../data/roles'
import LocaleContext from './LocaleContext'
import Section from './Section'

import formImage from '../images/community-hub/MyHR Nisha Using App.png';

class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '793068f5-1879-4572-86b6-9ab8170831c1',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
    <Section
      className="CommunityForm"
    >
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6} className="">
            <h3>Join the HR Community</h3>
            <p>Get the latest HR blogs, guides & videos delivered straight to your inbox.</p>
            <div id="hubspotForm"></div>
          </Col>
          <Col sm={12} md={6} lg={6} className="form-image">
            <img src={formImage} />
          </Col>
        </Row>
      </Container>
    </Section>
    );
  }
}

export default HubspotForm