import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import RichText from './RichText'

import './ContentSection.scss'


export default ({
  body = null,
  title = null,
  className = '',
  image,
  imagePosition
}) => {

  const bodyRT = (body && body.json) 
    ? <RichText json={body.json}/>
    : null

  const colImagePosClass = (imagePosition === 'right')
    ? 'order-md-last'
    : 'order-md-first'

  return (
    <Section className={`ContentSection ${className ? className : ''}`}>
      <Container>
        {title &&
          <h3 className="mb-4">{title}</h3>
        }
        <Row>
          {image && image.file && image.file.url ? (
            <>
              <Col md={7}>
                {bodyRT}
              </Col>
              <Col md={5} className={colImagePosClass}>
                <img
                  src={image.file.url}
                  alt={image.title}
                  className="mb-4"
                />
              </Col>
            </>
          ) : (
            <Col>
              {bodyRT}
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  )
}


export const fragment = graphql`
  fragment ContentfulContentSectionFragment on ContentfulContentSection {
    entryTitle
    title
    body {
      json
    }
    image {
      title
      file {
        url
      }
    }
    imagePosition
    className
  }
`