import React, { useState, useContext } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import Section from './Section'
import Stat from './Stat'
import Link from '../components/Link'
import RichText from './RichText'
import ModalVideo from './ModalVideo'
import Hero from '../components/Hero'
import LocaleContext from './LocaleContext'

import './StatsSectionHome.scss'

import customerStoryLogo from '../images/features/customer_story_logos/HSE.png'
import imageVid from '../images/features/customer_thumbnails/MyHR_Features_HSE_thumb.jpg'
import customerStoryLogoNZ from '../images/features/customer_story_logos/KAAR.png'
import imageVidNZ from '../images/features/customer_thumbnails/MyHR_Features_KAAR_thumb.jpg'
const video = {
  url: 'https://www.youtube.com/embed/kYU7cOWOGyM',
  channel: 'youtube'
}
const videoNZ = {
  url: 'https://www.youtube.com/embed/Inu4roqNskI',
  channel: 'youtube'
}

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}


export default ({
  body = null,
  stats
}) => {
  const locale = useContext(LocaleContext)

  return (
  <div>
    <Section className="BannerSection2 py-5">
      <Container>
        <Row className="my-5" data-aos="fade-in">
          <Col className="d-none d-md-block" md={{ size: 12 }} lg={{ size: 7 }}>
            {locale.value == 'en-AU' &&
            <ModalVideo {...video}>
              {({ toggle }) =>
                <div
                  className="UntangleHrSection__video"
                  onClick={toggle}
                >
                  <img
                    src={imageVid}
                    alt={'test'}
                  />
                  <div className="UntangleHrSection__video-play-icon">
                    <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
                  </div>
                </div>
              } 
            </ModalVideo>}
            {locale.value != 'en-AU' &&
            <ModalVideo {...videoNZ}>
              {({ toggle }) =>
                <div
                  className="UntangleHrSection__video"
                  onClick={toggle}
                >
                  <img
                    src={imageVidNZ}
                    alt={'test'}
                  />
                  <div className="UntangleHrSection__video-play-icon">
                    <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
                  </div>
                </div>
              } 
            </ModalVideo>}
          </Col>
          <Col md={{ size: 12, offset: 0 }} lg={{ size: 5, offset: 0 }}>
            <div className="h-100 d-flex align-items-start flex-column justify-content-center pl-5">
              <div>
                <h2 className="h1 mb-2">Don’t just take our word for it.</h2>
                <div className="d-block d-md-none my-3">
                  {locale.value == 'en-AU' &&
                  <ModalVideo {...video}>
                    {({ toggle }) =>
                      <div
                        className="UntangleHrSection__video"
                        onClick={toggle}
                      >
                        <img
                          src={imageVid}
                          alt={'test'}
                        />
                        <div className="UntangleHrSection__video-play-icon">
                          <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
                        </div>
                      </div>
                    } 
                  </ModalVideo>}
                  {locale.value != 'en-AU' &&
                  <ModalVideo {...videoNZ}>
                    {({ toggle }) =>
                      <div
                        className="UntangleHrSection__video"
                        onClick={toggle}
                      >
                        <img
                          src={imageVidNZ}
                          alt={'test'}
                        />
                        <div className="UntangleHrSection__video-play-icon">
                          <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
                        </div>
                      </div>
                    } 
                  </ModalVideo>}
                </div>
                <p className="my-4">
                  “The support that MyHR has given me has been immense. To me it’s just like having my own HR support person within my business, because it’s that accessible.”
                </p>
                {locale.value == 'en-AU' &&
                <div className="customerStoryBy">
                  <p>
                    <strong>Amanda Tait</strong><br />
                    <span>HR Coordinator, Height Safety Engineers</span>
                  </p>
                  <img src={customerStoryLogo} className="customerStoryLogo" />
                </div>}
                {locale.value != 'en-AU' &&
                <div className="customerStoryBy">
                  <p>
                    <strong>Kelvin Armstrong</strong><br />
                    <span>CEO, KAAR</span>
                  </p>
                  <img src={customerStoryLogoNZ} className="customerStoryLogo" />
                </div>}
                <Link
                  type="button"
                  color="electric-blue"
                  href="/customer-stories"
                  localePrefix={true}
                >
                  More customer stories
                </Link>
                <p>&nbsp;</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>

    <Section className="py-0">
      <Container>
        <hr className="position-relative" />
      </Container>
    </Section>
  
    <Section className="StatsSection">
      {body && body.json &&
        <Section.Intro>
          <RichText json={body.json} />
        </Section.Intro>
      }
      <Container className="slick-container">
        <Slider {...SLIDER_SETTINGS}>
          {stats && stats.map((stat, i) => 
            <Stat {...stat} key={i}/>
          )}
        </Slider>
      </Container>
    </Section>

    <Hero variant={locale.value + "-integrations-banner"}>
      <h3>Integrate with leading software apps and create a powerful HR ecosystem</h3>
      <Link
        type="button"
        color="cyan"
        href="/integrations"
        localePrefix={true}
      >
        Learn More
      </Link>
    </Hero>
  </div>
)}

export const fragment = graphql`
  fragment ContentfulStatsSectionHomeFragment on ContentfulStatsSectionHome {
    entryTitle
    body {
      json
    }
    stats {
      ...ContentfulStatFragment
    }
  }
`