import React from 'react'
import { graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import Page from '../components/Page'

import './page.scss'

const PageTemplate = ({ data, pageContext }) => {
  const Component = <Page
    data={data.contentfulPage}
    locale={pageContext.locale}
  />

  if (data.contentfulPage.className == 'HomePage')
    return <ParallaxProvider>{Component}</ParallaxProvider>

  return Component
}
export default PageTemplate


export const query = graphql`
  query PageByID($pageId: String!) {
    contentfulPage(id: { eq: $pageId }) {
      ...ContentfulPageFragment
    }
  }
`