import React from 'react'
import { graphql } from 'gatsby'
import CountUp from 'react-countup'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'

import './Stat.scss'
import { useState } from 'react'



const getRandom = (min, max) => (
  (Math.random() * Math.floor(max))
)

const randomStartAndEnd = (stat) => {
  let end = stat;
  let start = getRandom(stat-(stat/4), stat-(stat/10));
  if (start < 0) {
    start = 0;
  }
  return {
    start,
    end
  }
}


export default ({
  label,
  icon,
  value,
  valueSuffix = null
}) => {
  const [ ready, setReady ] = useState(false)

  const Counter = () => {
    if (ready)
      return <CountUp
        className="Stat__counter" 
        {...randomStartAndEnd(parseInt(value))}
        duration={4}
        useEasing={false}
      />

    return null
  }

  return (
    <div
      className={classnames(
        'Stat',
        { 'is-ready': ready }
      )}
    >
      {icon && icon.fixed && icon.fixed.src &&
        <img
          className="Stat__icon"
          alt={icon.title}
          src={icon.fixed.src}
        />
      }
      <Counter/>
      {valueSuffix && label == "Active Employees" && 
        <span className="Stat__plus">+</span>
      }
      {valueSuffix && label != "Active Employees" && 
        <span className="Stat__plus">%</span>
      }
      {label &&
        <div
        className="Stat__label"
        dangerouslySetInnerHTML={{ __html: label }}
        />
      }
      <Waypoint
        topOffset="90%"
        onEnter={() => {
          setReady(true)
        }}
      />
    </div>
  )
}



export const fragment = graphql`
  fragment ContentfulStatFragment on ContentfulStat {
    label
    icon {
      title
      fixed(width: 100, height: 100) {
        src
      }
    }
    value
    valueSuffix
  }
`
