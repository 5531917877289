import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import ContactForm from './ContactForm'

import './ContactFormSection.scss'
import MapNZ from '../images/section/map-nz.jpg'
import MapAU from '../images/section/map-au-2.jpg'
import MapQT from '../images/section/map-qt.jpg'
import MapCH from '../images/section/map-ch.jpg'

export default () => (
  <Section className="ContactFormSection">
    <Container>
      <h1 className="mb-4 mb-md-5">Contact MyHR</h1>
      <Row>
        <Col md={8} lg={6} className="mb-5">
          <h2 className="h4 mb-4">Email</h2>
          <ContactForm />
        </Col>

        <Col md={4} lg={6}>
          <Row>
            <Col sm={6} md={12} lg={6} className="mb-3">
              <div className="mb-3">
                <h3 className="h4 mb-1">New Zealand</h3>
                <b className="text-electric-blue">
                  <a href="tel:0800 69 47 69">0800 MYHR NZ (69 47 69)</a>
                </b>
                <br />
              </div>
              <img
                src={MapNZ}
                className="mb-3"
                alt="Harbourside Business Park, 485C Rosebank Road, Auckland 1026"
              />
              <p>
                <strong>Auckland</strong><br />
                Harbourside Business Park
                <br />
                485C Rosebank Road
                <br />
                Auckland 1026
              </p>
            </Col>
            <Col sm={6} md={12} lg={6} className="mb-3">
              <div className="mb-3">
                <h3 className="h4 mb-1">Australia</h3>
                <b className="text-electric-blue">
                  <a href="tel:+61 2 7908 2240">+61 2 7908 2240</a>
                </b>
                <br />
              </div>
              <img
                src={MapAU}
                className="mb-3"
                alt="MyHR Australia, 55 Holt St, Surry Hills NSW 2010"
              />
              <p>
                <strong>Sydney</strong><br />
                55 Holt St
                <br/>
                Surry Hills NSW 2010
              </p>
            </Col>
            <Col sm={6} md={12} lg={6} className="mb-3">
              <img
                src={MapCH}
                className="mb-3"
                alt="MyHR Christchurch, 4 Ash Street, Christchurch Central City, Christchurch 8011"
              />
              <p>
                <strong>Christchurch</strong><br />
                4 Ash Street<br />
                Christchurch Central City<br />
                Christchurch 8011
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Section>
)
