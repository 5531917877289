import React from 'react'
import { Row, Col } from 'reactstrap'

import Link from '../Link'

export const FreeTrial = () => {
  return (
    <div className="width--21">
      <div className="text--uppercase text--size-34 text--weight-600 mb--1">
        Free Trial
      </div>
      <h4 className="popup-cta-title text--size-23 text--weight-200 mb--0-6">
        NZ's most cost effective HR, online &amp; on demand.
      </h4>
      <div className="popup-cta-text mb-1">
        <small>
          <p className="mb--1 line-height-1">
            All inclusive. No Hidden Costs. No Lock In Contracts.
          </p>
        </small>
      </div>

      <Row noGutters>
        <Col lg={6} sm={12}>
          <Link
            type="button"
            color="gray"
            className="d-block text-purple mb-3 mr-lg-2"
            href="/trial"
            localePrefix={true}
          >
            Start now
          </Link>
        </Col>
        <Col lg={6} sm={12}>
          <Link
            type="button"
            color="purple"
            className="d-block mb-3 ml-lg-2"
            href="/pricing"
            localePrefix={true}
          >
            Pricing
          </Link>
        </Col>
      </Row>
    </div>
  )
}
