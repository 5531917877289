import React, { useContext, useState } from 'react'
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { submit as hubspotSubmit } from '../utils/hubspotForm'
import { useLocale } from '../hooks/useLocale'

import './ContactForm.scss'
import { companySizes } from '../data/companySizes'
import { roles as hubspotRoles } from '../data/roles'
import LocaleContext from './LocaleContext'

class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '366d78ce-916e-4692-bb78-57644dfb4c45',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}

const ContactForm = () => {
  const [isUnexpectedError, setIsUnexpectedError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const locale = useContext(LocaleContext)

  const { countryCode } = useLocale()
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      country: countryCode,
    },
  })

  const onSubmit = async data => {
    setIsSuccess(false)
    setIsUnexpectedError(false)
    const fields = Object.keys(data)
      .map(name => ({ name, value: data[name] }))
      .filter(({ value }) => !!value)

    fields.push({
      name: 'billing_location_country_iso',
      value: locale.value === 'en-AU' ? 'AU' : 'NZ',
    })

    try {
      const { status, errors } = await hubspotSubmit({
        ...HUBSPOT_SETTINGS,
        fields,
      })
      if (status === 'error') {
        console.warn('Unexpected errors: ', errors)
        setIsUnexpectedError(true)
        return
      }
      setIsSuccess(true)
      reset({ country: countryCode })
    } catch (e) {
      console.error('contact us submit error: ', e)
      throw e
    }
  }

  return (
    <HubspotForm />
  )
}

export default ContactForm
