export const roles = [
  {
    value: '',
    name: 'Role',
  },
  {
    value: 'Company owner',
    name: 'Business Owner',
  },
  {
    value: 'CEO / GM',
    name: 'CEO / GM',
  },
  {
    value: 'CFO/ Finance',
    name: 'CFO / Finance',
  },
  {
    value: 'HR Manager',
    name: 'HR Manager',
  },
  {
    value: 'Manager',
    name: 'Manager',
  },
  {
    value: 'Administrator',
    name: 'Administrator',
  },
]
