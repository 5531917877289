import React, { useEffect, useState } from 'react'

import { Row, Col, Button } from 'reactstrap'
import uniqBy from 'lodash/fp/uniqBy'
import filter from 'lodash/fp/filter'
import sortBy from 'lodash/sortBy'

import './TrialForm.scss'

import {
  defaultLocations,
  getLocations,
  defaultCountry,
} from '../data/locations'
import { postForm } from './lib/postForm'
import { useLocale } from '../hooks/useLocale'
import { roles as hubspotRoles } from '../data/roles'
import { companySizes } from '../data/companySizes'
import { useAppMaintenanceMode } from '../hooks/useAppMaintenanceMode'

import { useAPIHost } from './lib/useAPIHost'
import { pageUri } from '../utils/hubspotForm'

const TrialFormLayout = ({
  skipTrial,
  showSkipTrial = true,
  submitLabel,
  termsAndConditionsFile,
  onSubmit,
  onChange,
  intro,
  companySizeLabel,
  country,
  countries = [],
  regions = [],
}) => {
  const [isAppInMaintenaceMode, maintenanceMessage] = useAppMaintenanceMode()
  return (
    <div>
      {/* Only show intro on the form not the thank you page */}
      <div className="TrialFormSection__intro">
        <h1 className="TrialFormSection__title">
          Smart HR software. Expert HR support.
        </h1>
        {intro ? (
          <div dangerouslySetInnerHTML={{ __html: intro }} />
        ) : (
          <h2>Start your free trial now</h2>
        )}
      </div>
      {isAppInMaintenaceMode ? (
        maintenanceMessage
      ) : (
        <>
          <form
            className="TrialForm"
            onSubmit={onSubmit}
            method="post"
            encType="multipart/form-data"
            data-view="trialform"
          >
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    placeholder="First Name"
                    required
                    onChange={onChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    required
                    onChange={onChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="text"
                    name="company_name"
                    className="form-control"
                    placeholder="Company"
                    required
                    onChange={onChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <select
                    name="company_size"
                    className="form-control form-control-unselected"
                    required
                    onChange={onChange}
                  >
                    {companySizes.map(companySize => (
                      <option value={companySize.value}>
                        {companySize.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={onChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <select
                    name="metadata.hubspot_role"
                    className="form-control form-control-unselected"
                    required
                    onChange={onChange}
                  >
                    {hubspotRoles.map(role => (
                      <option value={role.value}>{role.name}</option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Phone"
                    required
                    onChange={onChange}
                  />
                </div>
              </Col>
              <Col lg={6}></Col>
            </Row>
            {countries && countries.length > 0 && (
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <select
                      name="billing_location_country_iso"
                      className="form-control form-control-unselected"
                      required
                      onChange={onChange}
                    >
                      <option value="">Country</option>
                      {countries.map(({ label, value }) => (
                        <option
                          value={value}
                          key={value}
                          selected={country === value}
                        >
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  {regions && regions.length > 0 && (
                    <div className="form-group">
                      <select
                        name="billing_location_region_iso"
                        className="form-control form-control-unselected"
                        required
                        onChange={onChange}
                      >
                        <option value="">State</option>
                        {regions.map(({ label, value }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            <div className="form-group">
              <span className="checkbox-inline">
                <label htmlFor="terms_agreed">
                  <input
                    id="terms_agreed"
                    type="checkbox"
                    name="terms_agreed"
                    required
                    onChange={onChange}
                  />{' '}
                  I have read and I agree to the MyHR{' '}
                  <a
                    href={
                      termsAndConditionsFile
                        ? termsAndConditionsFile
                        : 'https://app.myhr.works/terms-and-conditions'
                    }
                    target="terms-and-conditions"
                    rel="noopener"
                  >
                    <b>Terms of Use</b>
                  </a>
                </label>
              </span>
            </div>
            <div>
              <Button color="cyan" type="submit">
                {submitLabel ? submitLabel : 'START NOW'}
              </Button>
              {showSkipTrial && (
                <span className="skip-trial ml-3">
                  <Button color="electric-blue" onClick={skipTrial}>
                    Skip Trial
                  </Button>
                  <p className="skip-trial--message">
                    I’m ready to become a customer
                  </p>
                </span>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  )
}

const WelcomeToMyHR = ({ email }) => (
  <Row>
    <Col lg={12} style={{ minHeight: '400px' }}>
      <h2>Welcome to MyHR!</h2>
      <p>
        To complete your signup, please{' '}
        <b>find the email we&apos;ve sent to {email}</b> and click the link in
        it.
      </p>
      <p>
        We&apos;re looking forward to working with you to make HR at your
        company easy.
      </p>
    </Col>
  </Row>
)

const companySize = () => {
  const companySizeField = document.querySelector(
    'form[data-view="trialform"] [name="company_size"]'
  )
  let selectedCompanySize = '1-10'
  for (let j = 0; j < companySizeField.options.length; j++) {
    if (companySizeField.options[j].selected) {
      selectedCompanySize = companySizeField.options[j].value
    }
  }

  let companySize = 5
  switch (selectedCompanySize) {
    case '31+':
      companySize = 31
      break
    case '21-30':
      companySize = 21
      break
    case '11-20':
      companySize = 11
      break
    case '1-10':
    default:
      companySize = 1
      break
  }
  return companySize
}

const getCountryOptions = locations => {
  return uniqBy(
    ({ value }) => value,
    locations.map(({ country_iso: value, country_name: label }) => ({
      label,
      value,
    }))
  )
}

const regionsOptionsForCountryFactory = locations => matching_country_iso => {
  const locationsForCountry = filter(
    ({ country_iso }) => country_iso === matching_country_iso,
    locations
  )
  return sortBy(
    locationsForCountry
      .filter(({ region_iso, region_name }) => region_iso && region_name)
      .map(({ region_iso: value, region_name: label }) => ({ value, label })),
    'value'
  )
}

export const TrialForm = props => {
  const apiHost = useAPIHost()
  const [locations, setLocations] = useState(defaultLocations)
  const { countryCode } = useLocale()

  const getRegionOptionsForCountry = regionsOptionsForCountryFactory(locations)

  const [country, setCountry] = useState(countryCode || defaultCountry)
  const [countries, setCountries] = useState(getCountryOptions(locations))
  const [regions, setRegions] = useState(getRegionOptionsForCountry(country))

  const [state, setState] = useState({
    values: {
      billing_location_country_iso: country,
    },
    submitted: false,
    error: null,
    errors: {},
  })

  useEffect(() => {
    getLocations().then(locations => {
      setLocations(locations)
    })
  }, [])

  useEffect(() => {
    setCountries(getCountryOptions(locations))
  }, [locations])

  useEffect(() => {
    setRegions(getRegionOptionsForCountry(country))
  }, [country])

  const onSubmit = evt => {
    // trial package
    const package_id = 1
    // if we got called with an event we are skipping trial calculate the package
    if (evt) {
      evt.preventDefault()
    }
    const form = document.querySelector('form[data-view="trialform"]')
    if (!form) {
      if (console) console.log('did not find form')
      return
    }
    if (!form.checkValidity()) {
      form.reportValidity()
      return
    }

    const signupValues = {
      ...state.values,
      //terms_agreed: true, // Can't get this far without agreeing
      metadata: {
        ...state.values.metadata,
        ...props.signupMetadata,
        // NOTE: this is used for tracking form submissions in HubSpot via brochure page
        signup_page_url: pageUri(),
      },
      package_id,
    }

    const setSubmitted = () => {
      setState({
        ...state,
        submitted: true,
      })
    }
    const setError = error => {
      setState({
        ...state,
        error,
      })
    }

    postForm('trialform', `${apiHost}/api/member/signup`)(signupValues)
      .then(() => {
        setSubmitted()
      })
      .catch(error => {
        setError(error.response)
      })
  }

  const onChange = evt => {
    let value = evt.target.value
    const key = evt.target.name
    if (key === 'terms_agreed') {
      value = !!(value == 'on')
    }
    const field = document.querySelector(
      `form[data-view="trialform"] [name="${key}"]`
    )
    if (field && value !== state.values[key]) {
      field.setCustomValidity('')
    }
    // Handle locale just for this one form
    if (key === 'billing_location_country_iso' && value !== state.values[key]) {
      setCountry(value)
    }
    if (key && key.match(/^metadata./)) {
      const metadataKey = key.replace('metadata.', '')
      return setState({
        ...state,
        values: {
          ...state.values,
          metadata: {
            ...(state.values.metadata || {}),
            [metadataKey]: value,
          },
        },
      })
    }

    setState({
      ...state,
      values: {
        ...state.values,
        [key]: value,
      },
    })
  }

  if (state.submitted) {
    return <WelcomeToMyHR email={state.values.email} />
  }
  return (
    <TrialFormLayout
      {...props}
      onSubmit={onSubmit}
      onChange={onChange}
      country={country}
      countries={countries}
      regions={regions}
    />
  )
}

export default TrialForm
