import React from 'react'
import { graphql } from 'gatsby'

import './Customer.scss'


export default({ name, logo }) =>
  <div className="mapping">
    {logo && logo.file && logo.file.url &&
      <img src={logo.file.url} alt={name} />
    }
  </div>


export const fragment = graphql`
  fragment ContentfulCustomerFragment on ContentfulCustomer {
    name
    logo {
      file {
        url
      }
    }
  }
`
