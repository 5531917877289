import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'

import './FeatureSection.scss'


export default ({
  title,
  feature,
  imagePosition = 'left',
  backgroundColor
}) => {

  return (
    <Section
      className="FeatureSection"
      id={feature.hash}
      style={{ backgroundColor }}
    >
      <Container>
        <h3 className="mb-5">{title}</h3>
        <Row>
          <Col lg={4} className={(imagePosition === 'right') ? 'order-lg-last' : null}>
            {feature.image && feature.image.file && feature.image.file.url &&
              <img src={feature.image.file.url} alt={feature.image.title}/>
            }
          </Col>
          <Col lg={8}>
            <Row>
              {feature.information && feature.information.map((item, index) => {
                const content = item.content && item.content.childMarkdownRemark && item.content.childMarkdownRemark.html

                return (
                  <Col md={6} className="mb-4" key={index}>
                    <h4 className="h6 mb-2">{item.title}</h4>
                    {content &&
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    }
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>        
      </Container>
    </Section>
  )
}


export const fragment = graphql`
  fragment ContentfulFeatureSectionFragment on ContentfulFeatureSection {
    title
    feature {
      hash
      image {
        title
        file {
          url
        }
      }
      information {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    backgroundColor
    imagePosition
  }
`