import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import './TeamMember.scss'


export default ({
  name,
  position = null,
  photo = null,
  linkedIn = null,
  email = null,
}) =>
  <div className="TeamMember">
    <div className="TeamMember__photo">
      {photo && photo.file && photo.file.url &&
        <img src={photo.file.url} alt={name}/>
      }
    </div>
    <div className="text-center py-3">
      <div className="font-weight-bold text-electric-blue">{name}</div>
      <div>{position}</div>
      <div className="mt-1">
        {linkedIn &&
          <a
            className="TeamMember__social"
            href={linkedIn}
            target="_blank"
            rel="noopener"
          >
            <FontAwesomeIcon icon={faLinkedinIn}/>
          </a>
        }
        {email &&
          <a
            className="TeamMember__social"
            href={`mailto:${email}`}
          >
            <FontAwesomeIcon icon={faEnvelope}/>
          </a>
        }
      </div>
    </div>
  </div>



export const teamMemberFragment = graphql`
  fragment ContentfulTeamMemberFragment on ContentfulTeamMember {
    name
    position
    photo {
      file {
        url
      }
    }
    linkedIn
    email
  }
`
