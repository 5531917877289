import React, { useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faPlay } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import classnames from 'classnames';

import Section from './Section'
import LocaleContext from './LocaleContext'
import TrustedBySection from './TrustedBySection'
import CustomerStorySliderSection from './CustomerStorySliderSection'
import ModalVideo from './ModalVideo'

import customerStories from '../data/customerStories'

import './HomeInternalNav.scss'

import home2022_1 from '../images/home2022/MyHR-Home_Hiring-and-Onboarding@2x.png'
import home2022_2 from '../images/home2022/MyHR-Home_Compliance-and-advisory@2x.png'
import home2022_3 from '../images/home2022/MyHR-Home_Admin-and-Reporting@2x.png'
import home2022_4 from '../images/home2022/MyHR-Home_Performance-and-Development@2x.png'
import home2022_5 from '../images/home2022/MyHR-Home_Leave-and-payroll@2x.png'

import logo1 from '../images/always-on/MyHR-Performance-Reviews-Landing-JobAdder@2x.png'
import logo2 from '../images/always-on/MyHR-Performance-Reviews-Landing-xero@2x.png'
import logo3 from '../images/always-on/MyHR-Performance-Reviews-Landing-CloudPayroll@2x.png'
import logo4 from '../images/always-on/MyHR-Performance-Reviews-Landing-zapier@2x.png'
import logo5 from '../images/empiraa/Empiraa-CMYK_D_Black.png'

import logo1nz from '../images/always-on/MyHR-Performance-Reviews-Landing-JobAdder@2x.png'
import logo2nz from '../images/home2022/payhero.png'
import logo3nz from '../images/home2022/iPayroll.png'
import logo4nz from '../images/always-on/MyHR-Performance-Reviews-Landing-zapier@2x.png'
import logo5nz from '../images/empiraa/Empiraa-CMYK_D_Black.png'

import carpetcourt from '../images/home2022/MyHR-Home_CarpetCourt@2x.png'
import roosters from '../images/home2022/MyHR-Home_Roosters@2x.png'
import hse from '../images/home2022/MyHR-Home_HSE@2x.png'
import wsw from '../images/home2022/Western_City_Wanderers.png'


const AUVideo = {
  url: 'https://www.youtube.com/embed/DQ8fs_weH5E',
  channel: 'youtube',
}
const NZVideo = {
  url: 'https://www.youtube.com/embed/bG-lKzZmPxU',
  channel: 'youtube',
}
import imageVidMain from '../images/section/MyHR_Home_Video.jpg'
import BookDemoSection from './BookDemoSection';



export default () => {
  const locale = useContext(LocaleContext);

  useEffect(() => {
    // Animations
    AOS.init();
  }, [])

  return (
    <div>
      {locale.value == 'en-NZ' && (
        <TrustedBySection
          items={[
            <img src="//images.ctfassets.net/euxb051oplm7/7tVsftUJJOfWtvtgtABIdc/9929e636dfd04c5f386ee7ab5b5e9e4e/Hell_Pizza_Logo.png" alt="Hell" />,
            <img src="//images.ctfassets.net/euxb051oplm7/4PErs9z5Tq6I14YscMoHO9/1628f87067ae0c2fbcdbdf92569d3eeb/Warriors_Logo.png" alt="The Warriors" />,
            <img src="//images.ctfassets.net/euxb051oplm7/4f6BtGKBVsuftkQNSIPc6N/8a6b34f154cb0c9ac0bfa6c2bd6f936b/MyHR-Home_Resene_2x.png" alt="Resene" className="resene" />,
            <img src="//images.ctfassets.net/euxb051oplm7/6wUzGpHwKkcncC5ucEGDpu/a910f1087c21edbe7a63fe63c9388b98/Mazda_Logo.png" alt="Mazda" />,
            <img src="//images.ctfassets.net/euxb051oplm7/5w72c4FZqWC1Wf4nHOx7II/59badd3061cc0146b0808cf3d01fa426/WWF_Logo.png" alt="WWF" />,
            <img src="//images.ctfassets.net/euxb051oplm7/1lIYDrybf2LGgOWYYtyzEn/bf44e3a23d616ba9c4f2641e62d2742c/MyHR-Home_Lighting_Direct_2x.png" alt="Lighting Direct" className="lightingdirect" />,
          ]}
        />
      )}

      {locale.value == 'en-AU' && (
        <TrustedBySection
          items={[
            <img src={carpetcourt} className="carpetcourt" alt="Carpet Court" />,
            <img src={roosters} className="roosters" />,
            <img src="//images.ctfassets.net/euxb051oplm7/5w72c4FZqWC1Wf4nHOx7II/59badd3061cc0146b0808cf3d01fa426/WWF_Logo.png" alt="WWF" />,
            <img src={wsw} className="roosters" alt="Western City Wanderers" />,
            <img src="//images.ctfassets.net/euxb051oplm7/7tVsftUJJOfWtvtgtABIdc/9929e636dfd04c5f386ee7ab5b5e9e4e/Hell_Pizza_Logo.png" alt="Hell" />,
            <img src={hse} className="hse" alt="HSE" />,
          ]}
        />
      )}

      {locale.value == 'en-AU' &&
        <Section className="advice-line bg-gradient-navy-blue text-white">
          <Container className="my-4">
            <Row>
              <Col lg={{ size: 4}} className="mb-3">
                <h4>Advice line</h4>
                <h3>Free HR Advice</h3>
              </Col>
              <Col lg={{ size: 8}}>
                <p className="mb-4">Call our HR Advice Line for a free 15min HR consultation on any employment issue. Talk to a HR expert today.</p>
                <Button color="cyan" href="tel:+611800694728" className="mr-3">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 ml-0" style={{ transform: "rotate(90deg)" }}/>
                  Call now: 1800 694 728
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>
      }

      <Section className={classnames(
        "Content",
        {"main-video": locale.value != 'en-AU'},
        {"pb-0": locale.value == 'en-AU'},
      )}>
        <Container>
          {locale.value == 'en-NZ' && (<h1 className="text-center mt-5 mb-5">The HR Platform for SME</h1>)}
          {locale.value == 'en-AU' && (<h1 className="text-center mt-5 mb-5">The HR and Payroll platform for SME</h1>)}

          <Row className={classnames(
            {"my-6": locale.value != 'en-AU'},
            {"mt-6": locale.value == 'en-AU'},
          )}>
            <Col
            md={{ size: 8, offset: 2 }}
            lg={locale.value == 'en-AU' ? { size: 6, offset: 3 } : { size: 8, offset: 2 }}
            className="text-center"
          >
                {locale.value == 'en-AU' && (
                <ModalVideo {...AUVideo}>
                  {({ toggle }) => (
                    <div className="UntangleHrSection__video2" onClick={toggle}>
                      <img src={imageVidMain} alt={'Video play'} />
                      <div className="UntangleHrSection__video-play-icon">
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="icon play-icon"
                        />
                      </div>
                    </div>
                  )}
                </ModalVideo>
                )}

                {locale.value == 'en-NZ' && (
                <ModalVideo {...NZVideo}>
                  {({ toggle }) => (
                    <div className="UntangleHrSection__video2" onClick={toggle}>
                      <img src={imageVidMain} alt={'Video play'} />
                      <div className="UntangleHrSection__video-play-icon">
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="icon play-icon"
                        />
                      </div>
                    </div>
                  )}
                </ModalVideo>
                )}

            </Col>
          </Row>
        </Container>
      </Section>


      <Section className="Content">
        <Container>
          <a className="sectionLink" href="platform/hiring-and-onboarding">
          <Row className="mt-5">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_1} className="" alt="HR outsourcing company MyHR" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center">
              <h4>Hiring & Onboarding</h4>
              <h3>Hire talent fast and set them up for success</h3>
              <p>
                Hire talent fast with e-sign contracts then set them up for success with paperless onboarding. 
                Create automated, customised induction plans that trigger doc and policy acceptance, asset registration, 
                training, company culture and more.
              </p>
              <a className="sectionLinkArrow" href="platform/hiring-and-onboarding">Onboarding features <span>&rarr;</span></a>
            </Col>
          </Row>
          </a>

          <a className="sectionLink" href="platform/hr-compliance-and-advisory">
          <Row className="mt-5">
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>HR Compliance & HR Advisory</h4>
              <h3>Expert support for any employment issue</h3>
              <p>
                Cloud-based contracts, policies and HR documents on demand, built for your business and 100% paperless. 
                Get on-call HR advice, and run any process or make changes to your business with an HR expert backing 
                you every step of the way.
              </p>
              <a className="sectionLinkArrow" href="platform/hr-compliance-and-advisory">Compliance features <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_2} className="" alt="Best hr advice online MyHR" />
            </Col>
          </Row>
          </a>

          <a className="sectionLink" href="platform/admin-and-reporting">
          <Row className="mt-5">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_3} className="" alt="Best hr platform for small business MyHR" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center">
              <h4>Admin & Reporting</h4>
              <h3>Benchmark, manage and track your people</h3>
              <p>
                Create multi-level access with 2-factor authentication and employee self-service. Access org reports and insights, 
                and benchmark your people metrics against industry peers. Create custom reminders and track licences, visas, 
                training, events and attendance.
              </p>
              <a className="sectionLinkArrow" href="platform/admin-and-reporting">Admin features <span>&rarr;</span></a>
            </Col>
          </Row>
          </a>

          <a className="sectionLink" href="platform/performance-review-development">
          <Row className="mt-5">
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Performance & Development</h4>
              <h3>Boost employee performance and productivity</h3>
              <p>
                Maximise performance. Minimise admin. Build an engaged and productive team and cover all aspects of employee development 
                with user-friendly tools for OKR and goal setting, smart note capture, automated templates, PIPs, integrated LMS and more. 
                All injected with expert help from our HR advisory team.
              </p>
              <a className="sectionLinkArrow" href="platform/performance-review-development">Performance features <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_4} className="" alt="HR Performance & Development MyHR" />
            </Col>
          </Row>
          </a>

          {locale.value == 'en-NZ' && (
          <a className="sectionLink" href="platform/leave-and-payroll">
          <Row className="mt-5">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_5} className="" alt="Best hr software small business MyHR" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center">
              <h4>Leave & Payroll</h4>
              <h3>Streamline leave and payroll management</h3>
              <p>
                Integrate with Australasia's leading payroll providers like Xero, Cloud Payroll and Key Pay (Employment Hero). Sync employee 
                records and eliminate double-handling of data. Track, manage, and approve employee leave requests, review in a shared calendar, 
                and streamline the process with employee self-service.
              </p>
              <a className="sectionLinkArrow" href="platform/leave-and-payroll">Leave & payroll features <span>&rarr;</span></a>
            </Col>
          </Row>
          </a>)}

          {locale.value == 'en-AU' && (
          <a className="sectionLink" href="platform/leave-and-payroll">
          <Row className="mt-5">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_5} className="" alt="Best hr software small business MyHR" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center">
              <h4>Leave & Payroll</h4>
              <h3>Streamline leave and payroll management</h3>
              <p>
                Simplify payroll and leave management with MyHR Payroll or easily integrate with Australasia's leading payroll providers. 
                Sync employee records and eliminate double-handling of data. Track, manage, and approve employee leave requests, review in a 
                shared calendar, and streamline the process with employee self-service.
              </p>
              <a className="sectionLinkArrow" href="platform/leave-and-payroll">Leave & payroll features <span>&rarr;</span></a>
            </Col>
          </Row>
          </a>)}

        </Container>
      </Section>

      <Section className="integrations">
        <Container>
          <Row>
            <Col md={{ size: 8, offset: 2 }} className="d-flex text-center">
              <div className="w-100 py-md-6">
                <h3 className="mb-3">Integrate with best-in-class applications</h3>
              </div>
            </Col>
          </Row>
          <Row>
            {locale.value == 'en-AU' &&
            <Col md={12} className="logos">
              <img src={logo1} className="logo" alt="" />
              <img src={logo2} className="logo xero" alt="" />
              <img src={logo3} className="logo" alt="" />
              <img src={logo4} className="logo" alt="" />
              <img src={logo5} className="logo empiraa" alt="" />
            </Col>}

            {locale.value == 'en-NZ' &&
            <Col md={12} className="logos">
              <img src={logo1nz} className="logo" alt="" />
              <img src={logo2nz} className="logo" alt="" />
              <img src={logo3nz} className="logo" alt="" />
              <img src={logo4nz} className="logo" alt="" />
              <img src={logo5nz} className="logo empiraa" alt="" />
            </Col>}
          </Row>

          <Row>
            <Col md={12} className="d-flex text-center flex-column align-items-center">
              <Button
                color="outline-navy"
                href="/integrations"
                localePrefix={true}
                className="m-3 mb-5"
              >
                See integrations
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      {locale.value == 'en-NZ' && (
        <CustomerStorySliderSection
          title="What our customers say"
          stars={true}
          stories={[
            {
              ...customerStories['kaar'],
              "moreStoriesButton": true
            }
          ]}
        />
      )}
      
      {locale.value == 'en-AU' && (
        <CustomerStorySliderSection
          title="What our customers say"
          stars={true}
          stories={[
            {
              ...customerStories['hse'],
              "moreStoriesButton": true,
            },
          ]}
        />
      )}

      <BookDemoSection />

    </div>
  )

}