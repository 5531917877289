import React, { useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPrices } from '../data/prices'
import Link from './Link'
import {
  faPlay,
  faCheck,
  faTimes,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

import './PricingSectionPricingPage.scss'
import LocaleContext from './LocaleContext'
import Section from './Section'
import FaqSection from './FaqSection'
import ModalVideo from './ModalVideo'
import BookDemoSection from './BookDemoSection'
import PricingPlanCard from './PricingPlanCard'

import customerStoryLogo from '../images/features/customer_story_logos/Parkable.png'
import imageVid from '../images/features/customer_thumbnails/MyHR_Features_Parkable_Thumb.png'
const video = {
  url: 'https://www.youtube.com/embed/pBXoqzT5uqY',
  channel: 'youtube',
}

import customerStoryLogoAU from '../images/features/customer_story_logos/HSE2.png'
import imageVidAU from '../images/features/customer_thumbnails/MyHR_Features_HSE_thumb.jpg'

const videoAU = {
  url: 'https://www.youtube.com/embed/kYU7cOWOGyM',
  channel: 'youtube',
}

const featureJSON = {
  'ON-CALL HR SUPPORT': [
    {
      Feature: 'HR Advisory',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'HR Administration',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'HR Business Partner',
      Plan1: 'o',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Dedicated Account Manager',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  HIRING: [
    {
      Feature: 'Recruitment Guidance',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Interview & Applicant Tools',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Position Description Database ',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Position Description Creation',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Remuneration Benchmarking',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Salary Surveys',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'ATS Integration*',
      Plan1: 'n',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Recruitment Deputy*',
      Plan1: 'o',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Recruitment Strategy',
      Plan1: 'n',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Workforce Planning',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  'ONBOARDING ': [
    {
      Feature: 'Onboarding Strategy Advice',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Paperless Onboarding',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Onboarding Plans',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Automated Templates Library',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Automated Induction',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'E-Signature Contracts',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Digital Policy & Doc Acceptance',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Tax Compliance',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Asset Register',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Training Tracking',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'LMS Integration*',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  'ADMIN & REPORTING': [
    {
      Feature: 'Customised Level Access',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Employee Self Service',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: '2-Factor Authentication',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Encrypted File Transfer',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Smart Notes',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Org Reporting',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Insights and Benchmarking',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Data Reporting',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Reminders',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Training and Licence Tracking',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Work Visa Reporting',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Phone Support',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Forms',
      Plan1: 'n',
      Plan2: 'n',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Branding',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Integrations',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  COMPLIANCE: [
    {
      Feature: 'Employment Agreements',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'E-Signatures',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Standard Company Policies',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Company Policies',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Employee Handbooks',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Digital Document Acceptance',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Document Acceptance Tracking',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Standard Health & Safety Policies',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Safety and Incident Reporting',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Health & Safety Sytsem',
      Plan1: 'o',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  'EMPLOYMENT RELATIONS': [
    {
      Feature: 'Disciplinary Management',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Restructure Management',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Consultation Support',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Variation Letters',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'FT Extension Letters',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Changes to Pay/Hours',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Trial Period Terms',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Probation Period Terms',
      Plan1: ' ',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Medical Termination Support',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Excess Sick Leave Support',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Parental Leave Management',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Exit interview Forms',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Resignation Acceptance Letters',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Secondment Management',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Workplace Investigation Guidance',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Mediation',
      Plan1: 'o',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Workplace Investigations',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  'ENGAGEMENT & DEVELOPMENT': [
    {
      Feature: 'Paperless Performance Reviews',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Goals and OKR',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Development Plans',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Customised Performance Plans',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Performance Improvement Plans',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Automated Templates Library',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Check Ins',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'LMS Integration* ',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
    {
      Feature: 'Custom Surveys',
      Plan1: 'o',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
  'LEAVE MANAGEMENT': [
    {
      Feature: 'Leave Management',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Leave Requests & Approvals',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Employee Calendar',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Payroll Integrations*',
      Plan1: 'y',
      Plan2: 'y',
      Plan3: 'y',
    },
    {
      Feature: 'Payroll Integration (Custom)*',
      Plan1: 'n',
      Plan2: 'o',
      Plan3: 'y',
    },
  ],
}

const featurePayrollJSON = {
  Payroll: [
    {
      Feature: 'Pre-Built Modern Awards*',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Pay Conditions Engine',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Accounting Integration',
      Plan1: 'y',
      Plan2: 'y',
    },
    {
      Feature: 'Quarterly Super Processing',
      Plan1: 'y',
      Plan2: 'y',
    },
    {
      Feature: 'ATO Electronic Lodgements',
      Plan1: 'y',
      Plan2: 'y',
    },
    {
      Feature: 'Comprehensive Reporting',
      Plan1: 'y',
      Plan2: 'y',
    },
    {
      Feature: 'Flexible Payroll Management',
      Plan1: 'y',
      Plan2: 'y',
    },
  ],
  Rostering: [
    {
      Feature: 'Shift Swapping and Bidding',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Unavailability and Leave Visibility',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Award Based Costing',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Templates',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Budgeting',
      Plan1: 'n',
      Plan2: 'y',
    },
  ],
  'Time and Attendance ': [
    {
      Feature: 'Real Time Reporting',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Image Capture',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Clock In/Out via Mobile or iPad',
      Plan1: 'n',
      Plan2: 'y',
    },
    {
      Feature: 'Geo tagging',
      Plan1: 'n',
      Plan2: 'y',
    },
  ],
}

/**
 * centralised from pricing, if this diverges per locale in future again we can
 * add more structure here. Still much better to not have to update in multiple places.
 */
const fromPricing = {
  small: '$295',
  medium: '$420',
  enterprice: 'CALL US',
}

/**
 * Centralised pricing for payroll products to avoid having to update in multiple places
 */
const payrollPricing = {
  standard: '$4',
  premium: '$6',
}

export default ({ theme = 'dark' }) => {
  const locale = useContext(LocaleContext)
  const prices = getPrices(locale.value)

  const mobileShowHide = event => {
    event.preventDefault()
    var el = event.currentTarget
    var activePlan = el.getAttribute('data-plan')

    document
      .querySelectorAll('.plan1')
      .forEach(el => el.classList.remove('active'))
    document
      .querySelectorAll('.plan2')
      .forEach(el => el.classList.remove('active'))
    document
      .querySelectorAll('.plan3')
      .forEach(el => el.classList.remove('active'))

    document
      .querySelectorAll(activePlan)
      .forEach(el => el.classList.add('active'))

    document
      .querySelectorAll('.dropdown')
      .forEach(el => el.classList.remove('show'))
  }

  const mobileDropdown = event => {
    event.preventDefault()
    var el = event.currentTarget
    var activePlan = el.getAttribute('data-plan')

    document
      .querySelectorAll('.dropdown')
      .forEach(el => el.classList.toggle('show'))
  }

  const tabShowPayroll = event => {
    event.preventDefault()
    var el = event.currentTarget
    document
      .querySelectorAll('.pricing-tab')
      .forEach(el => el.classList.remove('active'))
    const elements = document.querySelectorAll('.pricing-tab')
    if (elements[1]) {
      elements[1].classList.add('active')
    }
    document.getElementById('pricing').style.display = 'none'
    document.getElementById('payroll').style.display = 'block'
    window.scrollTo(0, 0)
  }

  /**
   * New subscription cost calculation
   * See README-subscription-price-calculation.md
   */
  const calculatePrice = people => {
    let price = prices
      .sort((a, b) =>
        a.tier_up_to < b.tier_up_to ? -1 : a.tier_up_to == b.tier_up_to ? 0 : 1
      )
      .find(({ tier_up_to }) => people <= tier_up_to)
    if (!price) {
      return '??'
    }
    return price.tier_price_mode === 'flat' ? price.price : people * price.price
  }

  const calculatedCostText = people => {
    if (people === 1) return <b>Free for 15 days</b>
    else if (people >= 251) return <b>CALL US</b>

    return (
      <>
        <b>${calculatePrice(people)}</b>
        <small>
          /month +GST <b>{locale.currency}</b>
        </small>
      </>
    )
  }

  return (
    <div>
      <div id="pricing">
        <Section
          className={
            'PricingSectionPricingPage bg-gray-triangle-new-3-pricing' +
            (theme !== 'dark' ? ` theme-${theme}` : '')
          }
        >
          <Container>
            <Row>
              <Col md={6} lg={4} className="py-5">
                <PricingPlanCard
                  title="Small Business"
                  subtitle="UP TO 20 EMPLOYEES"
                  summary="For companies with no internal HR*"
                  price={{
                    prefix: 'From',
                    value: '$295',
                    suffix: '/month',
                  }}
                  calculateCost={{
                    minEmployees: 1,
                    maxEmployees: 251,
                    defaultEmployees: 10,
                    costText: calculatedCostText,
                  }}
                  description={
                    <>
                      <p className="font-weight-bold">Includes:</p>
                      <ul>
                        <li>HR Advisory (On-Call)</li>
                        <li>HR Documents</li>
                        <li>Paperless Onboarding</li>
                        <li>Performance Reviews</li>
                        <li>Leave Management</li>
                        <li>Payroll Integration</li>
                        <li>Training & Licence Tracking</li>
                        <li>Digital Doc Acceptance</li>
                      </ul>
                    </>
                  }
                  notes={
                    <>
                      <p>
                        *Make people management easy. Fit-for-purpose HR
                        documents, contracts, and compliance. Support for any
                        tricky HR issues, and an easy-to-use HRIS to manage
                        payroll, leave, and performance. It’s the HR department
                        for businesses that don’t have one.
                      </p>
                      <p className="font-weight-bold">No lock-in contract</p>
                    </>
                  }
                />
              </Col>
              <Col md={6} lg={4} className="py-5">
                <PricingPlanCard
                  title="Medium Business"
                  subtitle="21-100 EMPLOYEES"
                  summary="For companies with or without internal HR*"
                  price={{
                    prefix: 'From',
                    value: '$420',
                    suffix: '/month',
                  }}
                  calculateCost={{
                    minEmployees: 16,
                    maxEmployees: 251,
                    defaultEmployees: 21,
                    costText: calculatedCostText,
                  }}
                  description={
                    <>
                      <p className="font-weight-bold">
                        Includes everything in Small, plus:
                      </p>
                      <ul>
                        <li>HR Business Partner</li>
                        <li>Customised HR Documents</li>
                        <li>Recruitment Strategy</li>
                        <li>ATS Integration (Job Adder)</li>
                        <li>LMS Integration (ELMO)</li>
                        <li>Custom Surveys (optional)</li>
                        <li>Custom Data Reporting (optional)</li>
                        <li>Full-Service Recruitment (optional)</li>
                      </ul>
                    </>
                  }
                  notes={
                    <>
                      <p>
                        *Scale your business, not your admin team. Perfect for
                        growing businesses needing a customised HR product and
                        partner for additional support, issues management, and
                        documentation overload. Streamline recruitment,
                        onboarding, and performance, and access enterprise
                        products and services if required.
                      </p>
                      <p className="font-weight-bold">No lock-in contract</p>
                    </>
                  }
                />
              </Col>
              <Col md={6} lg={4} className="py-5">
                <PricingPlanCard
                  title="Enterprise"
                  subtitle="100+ EMPLOYEES"
                  summary="Customised HR solutions*"
                  price={{
                    value: 'Call us',
                  }}
                  calculateCost={{
                    minEmployees: 101,
                    maxEmployees: 251,
                    defaultEmployees: 101,
                    costText: calculatedCostText,
                  }}
                  description={
                    <>
                      <p className="font-weight-bold">
                        Includes everything in Small &amp; Medium, plus:
                      </p>
                      <ul>
                        <li>Dedicated Account Manager</li>
                        <li>Custom Data Reporting</li>
                        <li>Custom Branding</li>
                        <li>Editable HR Documents</li>
                        <li>Remuneration Benchmarking</li>
                        <li>Custom Forms</li>
                        <li>Custom Integrations</li>
                        <li>Leadership &amp; Culture</li>
                      </ul>
                    </>
                  }
                  notes={
                    <>
                      <p>
                        *Solutions tailored for large multi-dimensional
                        organisations. Whether you need dedicated recruitment
                        support or a custom API with your bespoke POS system or
                        payroll, we’ve got you covered. Contact one of our
                        enterprise solution specialists to build a plan that
                        fits your business and HR needs.
                      </p>
                      <p className="font-weight-bold">
                        Minimum 1-year contract
                      </p>
                    </>
                  }
                />
              </Col>
            </Row>
          </Container>
        </Section>

        {locale.value == 'en-NZ' && (
          <Section className="bg-bluegray">
            <Container className="d-flex align-items-center flex-column text-center"></Container>
          </Section>
        )}

        {locale.value == 'en-AU' && (
          <Section className="bg-bluegray">
            <Container className="d-flex align-items-center flex-column pb-5 text-center">
              <h3>
                Add Payroll from {payrollPricing.standard} per employee, per
                month
              </h3>
              <br />
              <div>
                <Link
                  color="cyan"
                  type="button"
                  onClick={tabShowPayroll}
                  localePrefix={true}
                  className="mr-2"
                >
                  Learn more
                </Link>
                <Link
                  color="radical-red"
                  type="button"
                  href="/book-a-demo"
                  localePrefix={true}
                  className="mr-2"
                >
                  Talk to us
                </Link>
              </div>
            </Container>
          </Section>
        )}

        {locale.value == 'en-AU' && (
          <div>
            <Section className="BannerSection py-5 mobile">
              <Container>
                <Row className="my-5">
                  <Col md={{ size: 12, offset: 0 }} lg={{ size: 6, offset: 0 }}>
                    <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                      <div>
                        <div className="d-block d-md-none my-3">
                          <ModalVideo {...videoAU}>
                            {({ toggle }) => (
                              <div
                                className="UntangleHrSection__video"
                                onClick={toggle}
                              >
                                <img src={imageVidAU} alt={'Video Thumbnail'} />
                                <div className="UntangleHrSection__video-play-icon">
                                  <FontAwesomeIcon
                                    icon={faPlay}
                                    className="icon play-icon"
                                  />
                                </div>
                              </div>
                            )}
                          </ModalVideo>
                        </div>
                        <h3 class="my-4 pr-6">
                          “The hands-on HR support has been incredibly
                          beneficial. They’ve helped us through a lot of sticky
                          situations.”
                        </h3>
                        <div className="customerStoryBy">
                          <p>Amanda Tait, HR Coordinator, HSE</p>
                          <img
                            src={customerStoryLogoAU}
                            className="customerStoryLogo"
                            alt="Logo"
                          />
                        </div>
                        <Link
                          type="button"
                          color="radical-red"
                          href="/customer-stories"
                          localePrefix={true}
                        >
                          More customer stories
                        </Link>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="d-none d-md-block"
                    md={{ size: 12 }}
                    lg={{ size: 6 }}
                  >
                    <ModalVideo {...videoAU}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVidAU} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section className="BannerSection py-6 desktop">
              <Container className="d-flex justify-content-center flex-column align-items-center">
                <h2>See MyHR in action with an interactive platform tour</h2>
                <script src="https://js.storylane.io/js/v1/storylane.js"></script>
                <iframe
                  id="frame"
                  className="sl-demo"
                  width="1240"
                  height="800"
                  frameBorder="0"
                  src="https://app.storylane.io/demo/3v8a7uh8dwyb"
                  name="sl-embed"
                  allow="fullscreen; camera; microphone"
                ></iframe>
              </Container>
            </Section>
          </div>
        )}

        {locale.value == 'en-NZ' && (
          <div>
            <Section className="BannerSection py-5 mobile">
              <Container>
                <Row className="my-5">
                  <Col md={{ size: 12, offset: 0 }} lg={{ size: 6, offset: 0 }}>
                    <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                      <div>
                        <div className="d-block d-md-none my-3">
                          <ModalVideo {...video}>
                            {({ toggle }) => (
                              <div
                                className="UntangleHrSection__video"
                                onClick={toggle}
                              >
                                <img src={imageVid} alt={'Video Thumbnail'} />
                                <div className="UntangleHrSection__video-play-icon">
                                  <FontAwesomeIcon
                                    icon={faPlay}
                                    className="icon play-icon"
                                  />
                                </div>
                              </div>
                            )}
                          </ModalVideo>
                        </div>
                        <h3 class="my-4 pr-6">
                          “MyHR... has meant I feel supported in decisions,
                          contracting and processes... the team really feels
                          like an extension of Parkable.”
                        </h3>
                        <div className="customerStoryBy">
                          <p>LOES ST. ATHER, PEOPLE & CULTURE LEAD, PARKABLE</p>
                          <img
                            src={customerStoryLogo}
                            className="customerStoryLogo parkable"
                            alt="Logo"
                          />
                        </div>
                        <Link
                          type="button"
                          color="radical-red"
                          href="/customer-stories"
                          localePrefix={true}
                        >
                          More customer stories
                        </Link>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="d-none d-md-block"
                    md={{ size: 12 }}
                    lg={{ size: 6 }}
                  >
                    <ModalVideo {...video}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVid} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section className="BannerSection py-6 desktop">
              <Container className="d-flex justify-content-center flex-column align-items-center">
                <h2>See MyHR in action with an interactive platform tour</h2>
                <script src="https://js.storylane.io/js/v1/storylane.js"></script>
                <iframe
                  id="frame"
                  className="sl-demo"
                  width="1240"
                  height="800"
                  frameBorder="0"
                  src="https://app.storylane.io/demo/3v8a7uh8dwyb"
                  name="sl-embed"
                  allow="fullscreen; camera; microphone"
                ></iframe>
              </Container>
            </Section>
          </div>
        )}

        <Section className="comparison_table">
          <Container>
            <div className="comparison_table-header comparison_table-header--c_white">
              <div className="h-container h-container--large ">
                <div className="comparison_table-row ">
                  <div className="comparison_table-title_block "></div>
                  <div className="comparison_table-product_block">
                    <h6>Small Business</h6>
                    <div className="comparison_table-price">
                      {locale.value == 'en-NZ' && (
                        <span className="comparison_table-price-value">
                          From {fromPricing.small}/month
                        </span>
                      )}
                      {locale.value == 'en-AU' && (
                        <span className="comparison_table-price-value">
                          From {fromPricing.small}/month
                        </span>
                      )}
                    </div>

                    {locale.value == 'en-NZ' && (
                      <a
                        href="/nz/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}

                    {locale.value == 'en-AU' && (
                      <a
                        href="/au/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}
                  </div>
                  <div className="comparison_table-product_block">
                    <h6>Medium Business</h6>
                    <div className="comparison_table-price">
                      {locale.value == 'en-NZ' && (
                        <span className="comparison_table-price-value">
                          From {fromPricing.medium}/month
                        </span>
                      )}
                      {locale.value == 'en-AU' && (
                        <span className="comparison_table-price-value">
                          From {fromPricing.medium}/month
                        </span>
                      )}
                    </div>

                    {locale.value == 'en-NZ' && (
                      <a
                        href="/nz/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}

                    {locale.value == 'en-AU' && (
                      <a
                        href="/au/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}
                  </div>
                  <div className="comparison_table-product_block last">
                    <h6>Enterprise</h6>
                    <div className="comparison_table-price">
                      <span className="comparison_table-price-value">
                        Call us
                      </span>
                    </div>

                    {locale.value == 'en-NZ' && (
                      <a
                        href="/nz/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}

                    {locale.value == 'en-AU' && (
                      <a
                        href="/au/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="comparison_table-tabs">
              <div className="tabs">
                <button
                  className="comparison_table-tab plan1 active"
                  onClick={mobileDropdown}
                  data-plan=".plan1"
                >
                  {locale.value == 'en-NZ' && (
                    <span className="comparison_table-tab-content">
                      Small Business - from {fromPricing.small}/month
                    </span>
                  )}
                  {locale.value == 'en-AU' && (
                    <span className="comparison_table-tab-content">
                      Small Business - from {fromPricing.small}/month
                    </span>
                  )}
                  <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                </button>
                <button
                  className="comparison_table-tab plan2"
                  onClick={mobileDropdown}
                  data-plan=".plan2"
                >
                  {locale.value == 'en-NZ' && (
                    <span className="comparison_table-tab-content">
                      Medium Business - from {fromPricing.medium}/month
                    </span>
                  )}
                  {locale.value == 'en-AU' && (
                    <span className="comparison_table-tab-content">
                      Medium Business - from {fromPricing.medium}/month
                    </span>
                  )}
                  <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                </button>
                <button
                  className="comparison_table-tab plan3 last"
                  onClick={mobileDropdown}
                  data-plan=".plan3"
                >
                  <span className="comparison_table-tab-content">
                    Enterprise
                  </span>
                  <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                </button>
              </div>
              <div className="dropdown">
                <button
                  className="comparison_table-tab plan1"
                  onClick={mobileShowHide}
                  data-plan=".plan1"
                >
                  {locale.value == 'en-NZ' && (
                    <span className="comparison_table-tab-content">
                      Small Business - from {fromPricing.small}/month
                    </span>
                  )}
                  {locale.value == 'en-AU' && (
                    <span className="comparison_table-tab-content">
                      Small Business - from {fromPricing.small}/month
                    </span>
                  )}
                </button>
                <button
                  className="comparison_table-tab plan2"
                  onClick={mobileShowHide}
                  data-plan=".plan2"
                >
                  {locale.value == 'en-NZ' && (
                    <span className="comparison_table-tab-content">
                      Medium Business - from {fromPricing.medium}/month
                    </span>
                  )}
                  {locale.value == 'en-AU' && (
                    <span className="comparison_table-tab-content">
                      Medium Business - from {fromPricing.medium}/month
                    </span>
                  )}
                </button>
                <button
                  className="comparison_table-tab plan3 last"
                  onClick={mobileShowHide}
                  data-plan=".plan3"
                >
                  <span className="comparison_table-tab-content">
                    Enterprise
                  </span>
                </button>
              </div>
            </div>

            <div className="comparison_table-data">
              {/*
            <div className="comparison_table-product_block plan1 active">
              <h6>Small Business</h6>
              <div className="comparison_table-price">
                {locale.value == 'en-NZ' && <span className="comparison_table-price-value">From {fromPricing.small}/month</span>}
                {locale.value == 'en-AU' && <span className="comparison_table-price-value">From {fromPricing.small}/month</span>}
              </div>

              {locale.value == 'en-NZ' && 
              <a href="https://info.myhr.works/meetings/myhr/myhrdemo" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}

              {locale.value == 'en-AU' && 
              <a href="https://info.myhr.works/meetings/myhr/software-demo-australia" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}
            </div>
            <div className="comparison_table-product_block plan2">
              <h6>Medium Business</h6>
              <div className="comparison_table-price">
                {locale.value == 'en-NZ' && <span className="comparison_table-price-value">From {fromPricing.medium}/month</span>}
                {locale.value == 'en-AU' && <span className="comparison_table-price-value">From {fromPricing.medium}/month</span>}
              </div>

              {locale.value == 'en-NZ' && 
              <a href="https://info.myhr.works/meetings/myhr/software-demo" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}

              {locale.value == 'en-AU' && 
              <a href="https://info.myhr.works/meetings/myhr/medium-software-demo" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}
            </div>
            <div className="comparison_table-product_block plan3">
              <h6>Enterprise Business</h6>
              <div className="comparison_table-price">
                <span className="comparison_table-price-value">Call us</span>
              </div>
              
              {locale.value == 'en-NZ' && 
              <a href="https://info.myhr.works/meetings/myhr/enterprise-demo" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}

              {locale.value == 'en-AU' && 
              <a href="https://info.myhr.works/meetings/myhr/enterprise-software-demo" className="h-button h-button--v_contained  h-button--c_primary h-button--s_default" target="_self">
                <span className="text-align">
                  <span className="h-button-content">Get started</span>
                </span>
              </a>}
            </div>
            */}

              {Object.entries(featureJSON).map(featureBlock => (
                <table className="table table-striped text-successtable-border border-light mt-5">
                  <thead className="border-light">
                    <tr>
                      <th scope="col" className="feature">
                        <strong>{featureBlock[0]}</strong>
                      </th>
                      <th scope="col" className="plan1"></th>
                      <th scope="col" className="plan2"></th>
                      <th scope="col" className="plan3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(featureBlock[1]).map(featureBlockData => (
                      <tr>
                        <th scope="row" className="feature">
                          {featureBlockData[1].Feature}
                        </th>
                        <td className="plan1 active">
                          {featureBlockData[1].Plan1 == 'y' && (
                            <span className="yes">
                              <FontAwesomeIcon icon={faCheck} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan1 == 'n' && (
                            <span className="no">
                              <FontAwesomeIcon icon={faTimes} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan1 == 'o' && (
                            <span className="optional">OPTIONAL</span>
                          )}
                        </td>
                        <td className="plan2">
                          {featureBlockData[1].Plan2 == 'y' && (
                            <span className="yes">
                              <FontAwesomeIcon icon={faCheck} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan2 == 'n' && (
                            <span className="no">
                              <FontAwesomeIcon icon={faTimes} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan2 == 'o' && (
                            <span className="optional">OPTIONAL</span>
                          )}
                        </td>
                        <td className="plan3">
                          {featureBlockData[1].Plan3 == 'y' && (
                            <span className="yes">
                              <FontAwesomeIcon icon={faCheck} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan3 == 'n' && (
                            <span className="no">
                              <FontAwesomeIcon icon={faTimes} className="" />
                            </span>
                          )}
                          {featureBlockData[1].Plan3 == 'o' && (
                            <span className="optional">OPTIONAL</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </Container>
        </Section>

        <FaqSection
          title="Pricing frequently asked questions"
          items={[
            {
              question: 'How does MyHR pricing work?',
              answer: (
                <>
                  <p>
                    The pricing structure is simple, with a fixed fee for
                    businesses with 20 employees or fewer. For companies with 20
                    or more employees, our HR pricing is priced on a
                    per-employee basis. That’s it. All-inclusive,
                    cost-effective, and with unlimited support.
                  </p>
                  <p>
                    Use our calculator above or get in touch with an HR
                    solutions specialist to see how much a MyHR subscription
                    would cost your business.
                  </p>
                </>
              ),
            },
            {
              question: 'What if I have contractors?',
              answer: (
                <p>
                  We understand there is less HR management needed for
                  contractors (also casuals and fixed-term employees), so we
                  charge a lower rate per contractor. Also, if you have spare
                  full-time seats, we use them first to keep your costs as low
                  as possible.
                </p>
              ),
            },
            {
              question: 'Are there any contracts?',
              answer: (
                <p>
                  For small and medium-sized clients (under 100 employees),
                  you’ll never have an annual contract. You’ll pay a simple
                  monthly fee and can cancel at any time. Given the complexities
                  of enterprise (larger) businesses, we realise there isn’t a
                  one-size-fits-all approach so we customise the solution and
                  require a small contractual commitment of a year.
                </p>
              ),
            },
            {
              question:
                'How will the price change if I add or remove people from my team?',
              answer: (
                <p>
                  As you add and remove people we will automatically adjust the
                  price to give you the best deal possible. Our pricing model is
                  designed to grow with your business, and we will automatically
                  apply volume discounts as your employee count rises.
                </p>
              ),
            },
            {
              question: 'Are there any upfront costs or additional fees?',
              answer: (
                <p>
                  Yes, there are upfront costs for onboarding, based on the
                  number of employees in the business. After that, you’ll pay a
                  simple, all-inclusive, fixed fee. To find out more about
                  MyHR’s onboarding process, get in touch with one of the team
                  here.
                </p>
              ),
            },
          ]}
        />

        <BookDemoSection
          title="Make HR easy"
          text={
            <p>
              Chat with our team to see if MyHR is the right solution for your
              business. Or check out our Platform Tour to learn more.
            </p>
          }
          variant="compact"
          showTrialButton={false}
          extraButtons={
            <Button
              color="outline-cyan"
              href="https://app.storylane.io/share/khkknhixy6ug"
              target="_blank"
            >
              Explore the platform
            </Button>
          }
        />
      </div>

      {locale.value == 'en-AU' && (
        <div id="payroll">
          <Section
            className={
              'PricingSectionPricingPage bg-gray-triangle-new-3-pricing pb-6' +
              (theme !== 'dark' ? ` theme-${theme}` : '')
            }
          >
            <Container>
              <Row>
                <Col
                  md={{ size: 5, offset: 1 }}
                  sm={{ size: 12, offset: 0 }}
                  className="d-flex"
                >
                  <div className="PricingSectionPricingPage__text w-100 pt-5 pb-3 text-left text-md-left">
                    <div className="PricingSectionPricingPage__rightBox">
                      <h3>Payroll Standard</h3>
                      <p className="for">
                        Fast and easy online payroll that integrates with HR.
                        Automate all your manual processes and improve Fair Work
                        compliance.
                      </p>
                      <p className="from">ADD</p>
                      {locale.value == 'en-AU' && (
                        <p className="price">
                          <span>{payrollPricing.standard}</span> per employee
                          /month
                        </p>
                      )}

                      {locale.value == 'en-AU' && (
                        <Link
                          color="electric-blue"
                          type="button"
                          href="/book-a-demo"
                          localePrefix={true}
                          className="w-100 mb-3"
                        >
                          Get started
                        </Link>
                      )}
                      <hr />
                      <p>
                        <strong>Includes:</strong>
                      </p>
                      <ul>
                        <li>Flexible Payroll Management</li>
                        <li>ATO Electronic Lodgements</li>
                        <li>Superannuation Processing</li>
                        <li>Comprehensive Reporting</li>
                        <li>Accounting Integration</li>
                      </ul>
                      {/*
                  <p className="footNote">
                    *Make people management easy. Fit-for-purpose HR documents, contracts, and compliance. 
                    Support for any tricky HR issues, and an easy-to-use HRIS to manage payroll, leave, 
                    and performance. It’s the HR department for businesses that don’t have one.
                  </p>
                  <p className="footNote">
                    <strong>No lock-in contract</strong>
                  </p>*/}
                    </div>
                  </div>
                </Col>
                <Col
                  md={{ size: 5, offset: 0 }}
                  sm={{ size: 12, offset: 0 }}
                  className="d-flex"
                >
                  <div className="PricingSectionPricingPage__text w-100 pt-5 pb-3 text-left text-md-left">
                    <div className="PricingSectionPricingPage__rightBox">
                      <h3>Payroll Premium</h3>
                      <p className="for">
                        Fast and easy payroll with additional functionality for
                        businesses with complex EBAs, rostering, or modern
                        awards.
                      </p>
                      <p className="from">ADD</p>
                      {locale.value == 'en-AU' && (
                        <p className="price">
                          <span>{payrollPricing.premium}</span> per employee
                          /month
                        </p>
                      )}

                      {locale.value == 'en-AU' && (
                        <Link
                          color="electric-blue"
                          type="button"
                          href="/book-a-demo"
                          localePrefix={true}
                          className="w-100 mb-3"
                        >
                          Get started
                        </Link>
                      )}
                      <hr />
                      <p>
                        <strong>Includes everything in Standard, plus:</strong>
                      </p>
                      <ul>
                        <li>Pre-Built Modern Awards</li>
                        <li>Time and Attendance</li>
                        <li>Rostering</li>
                        <li>Pay Conditions Engine</li>
                        <li>Geo Tagging</li>
                      </ul>
                      {/*
                  <p className="footNote">
                    *Scale your business, not your admin team. Perfect for growing businesses 
                    needing a customised HR product and partner for additional support, issues 
                    management, and documentation overload. Streamline recruitment, onboarding, 
                    and performance, and access enterprise products and services if required.
                  </p>
                  <p className="footNote">
                    <strong>No lock-in contract</strong>
                  </p>*/}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section className="comparison_table">
            <Container>
              <div className="comparison_table-header comparison_table-header--c_white">
                <div className="h-container h-container--large ">
                  <div className="comparison_table-row ">
                    <div className="comparison_table-title_block "></div>
                    <div className="comparison_table-product_block">
                      <h6>Payroll Standard</h6>
                      <div className="comparison_table-price">
                        <span className="comparison_table-price-value">
                          {payrollPricing.standard}/employee per month
                        </span>
                      </div>

                      {locale.value == 'en-AU' && (
                        <a
                          href="/au/book-a-demo"
                          className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                          target="_self"
                        >
                          <span className="text-align">
                            <span className="h-button-content">
                              Get started
                            </span>
                          </span>
                        </a>
                      )}
                    </div>

                    <div className="comparison_table-product_block last">
                      <h6>Payroll Premium</h6>
                      <div className="comparison_table-price">
                        <span className="comparison_table-price-value">
                          {payrollPricing.premium}/employee per month
                        </span>
                      </div>

                      <a
                        href="/au/book-a-demo"
                        className="h-button h-button--v_contained  h-button--c_primary h-button--s_default"
                        target="_self"
                      >
                        <span className="text-align">
                          <span className="h-button-content">Get started</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="comparison_table-tabs">
                <div className="tabs">
                  <button
                    className="comparison_table-tab plan1 active"
                    onClick={mobileDropdown}
                    data-plan=".plan1"
                  >
                    <span className="comparison_table-tab-content">
                      Payroll Standard - {payrollPricing.standard}/employee per
                      month
                    </span>
                    <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                  </button>
                  <button
                    className="comparison_table-tab plan2 last"
                    onClick={mobileDropdown}
                    data-plan=".plan2"
                  >
                    <span className="comparison_table-tab-content">
                      Payroll Premium - {payrollPricing.premium}/employee per
                      month
                    </span>
                    <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                  </button>
                </div>
                <div className="dropdown">
                  <button
                    className="comparison_table-tab plan1"
                    onClick={mobileShowHide}
                    data-plan=".plan1"
                  >
                    <span className="comparison_table-tab-content">
                      Payroll Standard - $4/employee per month
                    </span>
                  </button>
                  <button
                    className="comparison_table-tab plan2 last"
                    onClick={mobileShowHide}
                    data-plan=".plan2"
                  >
                    <span className="comparison_table-tab-content">
                      Payroll Premium - {payrollPricing.premium}/employee per
                      month
                    </span>
                  </button>
                </div>
              </div>

              <div className="comparison_table-data payroll">
                {Object.entries(featurePayrollJSON).map(featureBlock => (
                  <table className="table table-striped text-successtable-border border-light mt-5">
                    <thead className="border-light">
                      <tr>
                        <th scope="col" className="feature">
                          <strong>{featureBlock[0]}</strong>
                        </th>
                        <th scope="col" className="plan1"></th>
                        <th scope="col" className="plan2"></th>
                        <th scope="col" className="plan3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(featureBlock[1]).map(featureBlockData => (
                        <tr>
                          <th scope="row" className="feature">
                            {featureBlockData[1].Feature}
                          </th>
                          <td className="plan1 active">
                            {featureBlockData[1].Plan1 == 'y' && (
                              <span className="yes">
                                <FontAwesomeIcon icon={faCheck} className="" />
                              </span>
                            )}
                            {featureBlockData[1].Plan1 == 'n' && (
                              <span className="no">
                                <FontAwesomeIcon icon={faTimes} className="" />
                              </span>
                            )}
                            {featureBlockData[1].Plan1 == 'o' && (
                              <span className="optional">OPTIONAL</span>
                            )}
                          </td>
                          <td className="plan2">
                            {featureBlockData[1].Plan2 == 'y' && (
                              <span className="yes">
                                <FontAwesomeIcon icon={faCheck} className="" />
                              </span>
                            )}
                            {featureBlockData[1].Plan2 == 'n' && (
                              <span className="no">
                                <FontAwesomeIcon icon={faTimes} className="" />
                              </span>
                            )}
                            {featureBlockData[1].Plan2 == 'o' && (
                              <span className="optional">OPTIONAL</span>
                            )}
                          </td>
                          <td className="plan3"></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </div>
            </Container>
          </Section>
        </div>
      )}
    </div>
  )
}
