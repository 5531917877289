import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import Customer from './Customer'

import 'swiper/dist/css/swiper.css'
import './MoreCustomersSection.scss'


export default ({ customers }) => {
  
  return (
    <Section className="MoreCustomersSection">
      <Container className="text-center">
        <h2>More customers</h2>
        <Row className="justify-content-center my-3">
          {customers.map((customer, i) => (
            <Col xs={6} sm={4} md={3} lg={2} key={i}>
              <Customer {...customer} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}



export const fragment = graphql`
  fragment ContentfulMoreCustomersSectionFragment on ContentfulMoreCustomersSection {
    title
    customers {
      ...ContentfulCustomerFragment
    }
  }
`