import React from 'react'
import { graphql } from 'gatsby'
import { Container, Col, Row } from 'reactstrap'

import Section from './Section'
import TeamMember from './TeamMember'

import "./TeamSection.scss"


export default ({
  title = 'Team',
  team
}) =>
  <Section className="TeamSection">
    <Container>
      <h1 className="mb-5">{title}</h1>
      <Row>
        {team && team.map((member, index) => (
          <Col
            className="TeamSection__col mb-4"
            sm={6}
            md={4}
            key={index}
          >
            <TeamMember {...member} />
          </Col>
        ))}
      </Row>
    </Container>
  </Section>


export const fragment = graphql`
  fragment ContentfulTeamSectionFragment on ContentfulTeamSection {
    title
    team {
      ...ContentfulTeamMemberFragment
    }
  }
`