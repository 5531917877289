import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import Section from './Section'
import SubscribeForm from './SubscribeForm'

import './SubscribeSection.scss'
import image from '../images/section/subscribe.png'
import BookDemoSection from './BookDemoSection'


export default () => {
  return (
    <div>
      <Section className="SubscribeSection py-0">
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 0 }} lg={{ size: 6, offset: 1 }}>
              <div className="h-100 d-flex">
                <div className="SubscribeSection__text my-auto">
                  <h2>Stay up to date.</h2>
                  <p>Get our latest HR blogs, guides & videos delivered straight to your inbox.</p>
                  <SubscribeForm
                    buttonLabel="Subscribe"
                    themeColor="cyan"
                    textColor="white"
                    thankyouMessage={
                      <div>
                        <h2>Thanks for subscribing!</h2>
                        <p>Get ready to make HR easy.</p>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className="d-none d-md-block">
              <div className="SubscribeSection__image">
                <img src={image} alt="Sylvie - HR Expert" />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      
      <BookDemoSection />
    </div>
  )
}