import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'

import Section from './Section'
import RichText from './RichText'
import CustomerStoryCard, { customerStoryCardMapping } from './CustomerStoryCard'
import Link from './Link'
import LocaleContext from './LocaleContext'

import './CustomerStoriesSection.scss'

export default ({
  body,
  customerStories,
  showMoreButton
}) => {
  const locale = useContext(LocaleContext)

  return(
  <Section id="customer-stories" className="CustomerStoriesSection">
    {body && body.json &&
      <Section.Intro>
        <RichText json={body.json} />
      </Section.Intro>
    }
    <Container>
      <Row className="justify-content-center">

        {/*
        {locale.value == "en-NZ" && 
        <Col lg={12} md={12} className="mb-4">
          <div className="caseStudyBanner">
            <div className="hashTagContainer">
              <span className="hashTag"><span>#</span>customerstories</span>
            </div>
            <div className="bookContainer">

              3 min
            </div>
            <h3>Eat My Lunch</h3>
            <p>Learn about the challenges ‘Eat My Lunch’ faced prior to MyHR and how they 
               used MyHR to solve these issues and create a smooth HR operation.</p>
            <Link
              href="/customer-stories/eat-my-lunch"
              localePrefix={true}
              type="button"
              color="cyan"
            >
              READ MORE
            </Link>
          </div>
        </Col>}
        */}

      </Row>
      <Row className="justify-content-center">
        {customerStories && customerStories.map((story, index) => (
          <Col key={index} lg={4} md={6} className="mb-4">
            <CustomerStoryCard {...story} />
          </Col>
        ))}
      </Row>
      {showMoreButton && 
        <div className="text-center mt-4">
          <Link
            href="/customer-stories"
            localePrefix={true}
            type="button"
            color="radical-red"
          >
            MORE CUSTOMER STORIES
          </Link>
        </div> 
      }
    </Container>
  </Section>
  )
}


export const customerStoriesSectionMapping = ({
  customerStories,
  ...data
}) => ({
  ...data,
  customerStories: customerStories && customerStories.map(story => customerStoryCardMapping(story))
})



export const fragment = graphql`
  fragment ContentfulCustomerStoriesSectionFragment on ContentfulCustomerStoriesSection {
    body {
      json
    }
    customerStories {
      ...ContentfulCustomerStoryFragment
    }
    showMoreButton
  }
`