import React from 'react'
import { Row, Col, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import Section from './Section'
import ModalVideo from './ModalVideo'

import './UntangleHrSection.scss'
import image from '../images/section/untangle-video-image.jpg'
import Link from './Link'

const video = {
  url: 'https://www.youtube.com/embed/nUGQyeWIkms',
  channel: 'youtube'
}


export default () =>
  <Section className="UntangleHrSection">
    <Section.Intro>
      <h3>MyHR is complete HR. Surefire. Seamless.</h3>
    </Section.Intro>
    <Container className="text-center">
      <Row className="align-items-center">
        <Col lg={7} className="mb-3 mb-lg-0 order-lg-last">
          <ModalVideo {...video}>
            {({ toggle }) =>
              <div
                className="UntangleHrSection__video"
                onClick={toggle}
              >
                <img
                  src={image}
                  alt={'test'}
                />
                <div className="UntangleHrSection__video-play-icon">
                  <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
                </div>
              </div>
            } 
          </ModalVideo>
        </Col>
        <Col lg={5} className="text-lg-left mb-5">
          <p>
            Imagine a world where easy-to-use HR software combines with expert
            HR consultancy to make getting the best from your people simple as.
          </p>
          <p>
            No one else does HR like MyHR.
          </p>
          <Link
            type="button"
            color="radical-red"
            href="/features"
            localePrefix={true}
            className="mt-md-3"
          >
            Untangle HR Now
          </Link>
        </Col>
      </Row>
    </Container>
  </Section>
