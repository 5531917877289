import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { Container } from 'reactstrap'

import Section from './Section'
import Stat from './Stat'
import RichText from './RichText'

import './StatsSection.scss'

const SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}


export default ({
  body = null,
  stats
}) => 
  <Section className="StatsSection">
    {body && body.json &&
      <Section.Intro>
        <RichText json={body.json} />
      </Section.Intro>
    }
    <Container className="slick-container">
      <Slider {...SLIDER_SETTINGS}>
        {stats && stats.map((stat, i) => 
          <Stat {...stat} key={i}/>
        )}
      </Slider>
    </Container>
  </Section>

export const fragment = graphql`
  fragment ContentfulStatsSectionFragment on ContentfulStatsSection {
    entryTitle
    body {
      json
    }
    stats {
      ...ContentfulStatFragment
    }
  }
`