import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import Section from './Section'
import Partner from './Partner'

import LocaleContext from './LocaleContext'
import {bookADemoLinkSwitch} from './lib/bookADemoLinkSwitch'

import './PartnersSection.scss'
import bookDemoImage from '../images/case-study/Sales_Tile_2022-04.png'
import BookDemoSection from './BookDemoSection'


export default ({ partners }) => (
  <div>
    <Section className="PartnersSection">
      <Container>
        <Row>
          {partners && partners.map((partner, index) => (
            <Col sm={6} lg={4} className="mb-4 mb-sm-5" key={index} >
              <Partner {...partner} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
    
    <BookDemoSection />
  </div>
)

export const fragment = graphql`
  fragment ContentfulPartnersSectionFragment on ContentfulPartnersSection {
    partners {
      ...ContentfulPartnerFragment
    }
  }
`