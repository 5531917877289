import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'reactstrap'

import Carousel from './Carousel'
import Section from './Section'

import './TestimonialsSection.scss'


export default ({
  title = null,
  testimonials = null
}) => {

  const items = testimonials.map((testimonial, i) => {
    const { whoName, jobTitle, companyName } = testimonial
    const quote = testimonial.quote.childMarkdownRemark.rawMarkdownBody

    return (
      <div className="TestimonialsSection__item" key={i}>
        <blockquote>“{quote}”</blockquote>
        <div className="font-weight-bold">{whoName}</div>
        <span>
          {jobTitle && `${jobTitle}, `}
          {companyName}
        </span>
      </div>
    )
  })

  return (
    <Section className="TestimonialsSection bg-gray text-center">
      <Container>
        {title &&
          <h2 className="text-electric-blue mb-4">{title}</h2>
        }
        <Carousel items={items} />
      </Container>
    </Section>
  )
}



export const fragment = graphql`
  fragment ContentfulTestimonialsSectionFragment on ContentfulTestimonialsSection {
    title
    testimonials {
      whoName
      jobTitle
      companyName
      quote {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`