import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './Partner.scss'


export default ({
  title,
  linkLabel,
  link = null,
  ...props
}) => {
  const description = props.description && props.description.childMarkdownRemark && props.description.childMarkdownRemark.html
  const imageUrl = props.image && props.image.fixed && props.image.fixed.src

  return (
    <div className="Partner">
      <div className="Partner__logo">
        {imageUrl &&
          <img src={imageUrl} alt={title}/>
        }
      </div>
      <a className="Partner__container" href={link}>
        <div className="Partner__title">
          <h3>{title}</h3>
        </div>
        <div
          className="Partner__intro"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {link &&
        <a
          className="Partner__website"
          href={link}
          target="_blank"
          rel="noopener"
        >
          {linkLabel ? linkLabel : 'Visit website'} <FontAwesomeIcon icon={faArrowRight} />
        </a>
        }
      </a>
    </div>
  )
}


export const partnerFragment = graphql`
  fragment ContentfulPartnerFragment on ContentfulPartner {
    title
    link
    linkLabel
    image {
      fixed(height: 70, quality: 100) {
        src
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
  }
`