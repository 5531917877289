import React, { useState } from 'react'

import { FreeTrial } from './PopupCTA/FreeTrial'
import './PopupCTA.scss'

/**
 * A popup CTA, initially shows bottom left of page.
 */
export default props => {
  const { className = '', defaultOpen = true } = props
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const toggle = () => setIsOpen(!isOpen)
  const styles = {}
  if (!isOpen) {
    styles.display = 'none'
  }
  return (
    <div
      className={`popup-cta bg--dark bg--dark--geometrical-and-purple-fade ${className}`}
      style={styles}
    >
      <button
        type="button"
        className="popup-cta-close"
        aria-label="Close"
        onClick={toggle}
      >
        <span aria-hidden="true">×</span>
      </button>
      <div className="popup-cta-body">
        <FreeTrial />
      </div>
    </div>
  )
}
