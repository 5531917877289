import React from 'react'
import { graphql } from 'gatsby'

import Card from './Card'


export default ({
  listingImage,
  title,
  summary,
  slug,
  localePrefix,
  externalLink
}) =>
  <Card
    image={listingImage && listingImage.file && listingImage.file.url}
    title={title}
    text={summary && summary.childMarkdownRemark && summary.childMarkdownRemark.html}
    link={externalLink ? externalLink : `/${localePrefix}/white-paper/${slug}`}
    overlay={false}
  />


export const fragment = graphql`
  fragment ContentfulWhitePaperFragment on ContentfulWhitePaper {
    title
    slug
    localePrefix
    externalLink
    image {
      file {
        url
      }
    }
    listingImage {
      file {
        url
      }
    }
    summary {
      childMarkdownRemark {
        html
      }
    }
    intro {
      childMarkdownRemark {
        html
      }
    }
  }
`
