import map from 'lodash/map';

export const setErrors = formname => errors => {
  map(errors, (value, key) => {
    const field = document.querySelector(
      `form[data-view="${formname}"] [name="${key}"]`
    );
    if (field) {
      field.setCustomValidity(value);
      field.reportValidity();
    } else {
      console.warn(`could not find field ${key}`);
    }
    throw error
  });
};
