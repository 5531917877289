import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import LocaleContext from './LocaleContext'
import Section from './Section'
import Resources, { sortResources } from './Resources'



/**
 * Get all the resources by locale
 * 
 */
const getListing = () => {
  const locale = useContext(LocaleContext);
  const data =  useStaticQuery(query)

  let blogs = data.allContentfulBlogPost && data.allContentfulBlogPost.edges.map(({ node }) => ({ ...node, type: 'blog' }))
  blogs = blogs.filter(item => item.locale && item.locale.includes(locale.value))
  //blogs = blogs.filter(item => (item.category && item.category.includes("Recruitment")) || (item.category && item.category.includes("Onboarding")))
  blogs = sortResources(blogs)

  let guides = data.allContentfulGuide && data.allContentfulGuide.edges.map(({ node }) => ({ ...node, type: 'guide' }))
  guides = guides.filter(item => item.locale && item.locale.includes(locale.value))
  guides = sortResources(guides)

  let lives = data.allContentfulFacebookLive && data.allContentfulFacebookLive.edges.map(({ node }) => ({ ...node, type: 'live' }))
  lives = lives.filter(item => item.locale && item.locale.includes(locale.value))
  lives = sortResources(lives)

  let cta = data.allContentfulResourceCta && data.allContentfulResourceCta.edges.map(({ node }) => ({ ...node, type: 'cta' }))
  cta = sortResources(cta)

  let faqs = data.allContentfulBlogPost && data.allContentfulBlogPost.edges.map(({ node }) => ({ ...node, type: 'blog' }))
  faqs = faqs.filter(item => item.locale && item.locale.includes(locale.value))
  faqs = faqs.filter(item => item.tags && item.tags.includes("faqs"))
  faqs = sortResources(faqs)

  let covid = data.allContentfulBlogPost && data.allContentfulBlogPost.edges.map(({ node }) => ({ ...node, type: 'blog' }))
  covid = covid.filter(item => item.locale && item.locale.includes(locale.value))
  covid = covid.filter(item => item.tags && item.tags.includes("covid"))
  covid = sortResources(covid)
  
  let whitepapers = data.allContentfulWhitePaper && data.allContentfulWhitePaper.edges.map(({ node }) => ({ ...node, type: 'whitepaper' }))
  whitepapers = whitepapers.filter(item => item.localePrefix && item.localePrefix.includes(locale.countryCodeLower))
  whitepapers = sortResources(whitepapers)

  let all = sortResources([
    ...blogs,
    ...guides,
    ...lives,
    ...whitepapers
  ])

  if (cta) {
    var position = 0;

    for (var i = 0; i < cta.length; i++) {
      position += 14; // Disperse the CTAs through the list.
  
      if (all.length >= position) {
        all.splice(position, 0, cta[i]);
      }
    }
  }

  return {
    blogs,
    guides,
    lives,
    all,
    faqs,
    whitepapers
  }
}


export default (heroItem = null) => {
  const listing = getListing()
  return (
    <Section className="ResourceListingSection pt-0">
      <Resources
        heroItem={heroItem}
        settings={[
          {
            label: 'Blogs',
            hash: 'blogs',
            items: listing.blogs
          },
          {
            label: 'Guides',
            hash: 'guides',
            items: listing.guides
          },
          {
            label: 'White Papers',
            hash: 'whitepapers',
            items: listing.whitepapers
          },
          {
            label: 'Webinars',
            hash: 'live',
            items: listing.lives
          },
          {
            label: 'FAQs',
            hash: 'faqs',
            items: listing.faqs
          },
          {
            label: 'All',
            hash: 'all',
            items: listing.all
          },
        ]}
      />
    </Section>
  )
}


export const query = graphql`
  query ResourceListingSectionQuery {
    allContentfulBlogPost {
      edges {
        node {
          ...ContentfulBlogPostFragment
        }
      }
    }
    allContentfulGuide {
      edges {
        node {
          ...ContentfulGuideFragment
        }
      }
    }
    allContentfulFacebookLive {
      edges {
        node {
          ...ContentfulFacebookLiveFragment
        }
      } 
    }
    allContentfulResourceCta {
      edges {
        node {
          ...ContentfulResourceCtaFragment
        }
      }
    }
    allContentfulWhitePaper {
      edges {
        node {
          ...ContentfulWhitePaperFragment2
        }
      }
    }
  }
`