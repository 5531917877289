import React, { useContext } from 'react'
import { Container } from 'reactstrap'
import { graphql } from 'gatsby'

import Section from './Section'
import TrialForm from './TrialForm'
import LocaleContext from './LocaleContext'

import './TrialFormSection.scss'

export default ({
  submitLabel,
  showSkipTrial,
  companySizeLabel,
  ...props
}) => {
  const intro = props.intro && props.intro.childMarkdownRemark && props.intro.childMarkdownRemark.html
  const termsAndConditionsFile = props.termsAndConditionsFile && props.termsAndConditionsFile.file && props.termsAndConditionsFile.file.url
  const signupMetadata = JSON.parse(props.signupMetadata && props.signupMetadata.internal && props.signupMetadata.internal.content)
  const locale = useContext(LocaleContext)

  return (
    <Section className={locale.value=="en-NZ" ? 'TrialFormSection TrialFormSectionHereForYouNZ' : 'TrialFormSection TrialFormSectionHereForYou'}>
      <Container>
        <div className="TrialFormSection__body">
          <TrialForm
            intro={intro}
            submitLabel={submitLabel} 
            showSkipTrial={showSkipTrial} 
            termsAndConditionsFile={termsAndConditionsFile} 
            signupMetadata={signupMetadata}
            companySizeLabel={companySizeLabel}
          />
        </div>
      </Container>
    </Section>  
  )
}



export const fragment = graphql`
  fragment ContentfulTrialFormSectionFragment on ContentfulTrialFormSection {
    id
    intro {
      childMarkdownRemark {
        html
      }
    }
    submitLabel
    showSkipTrial
    companySizeLabel
    termsAndConditionsFile {
      file {
        url
      }
    }
    signupMetadata {
      internal {
        content
      }
    }
  }
`