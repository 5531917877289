import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import WhitePaperCard from './WhitePaperCard'


export default ({
  listing = []
}) =>
  <Section className="WhitePaperListingSection">
    <Container>
      <Row>
        {listing && listing.map((item, index) =>
          <Col key={index} lg={4} md={6} className="mb-4">
            <WhitePaperCard {...item} />
          </Col>
        )}
      </Row>
    </Container>
  </Section>


export const fragment = graphql`
  fragment ContentfulWhitePaperListingSectionFragment on ContentfulWhitePaperListingSection {
    listing {
      ...ContentfulWhitePaperFragment
    }
  }
`