import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Button, Modal } from 'reactstrap'
import Section from './Section'
import './BookDemoSection.scss'
import image from '../images/section/Get-Started-Sylvie-min.png'
import Link from './Link'

import './PlatinumServices.scss'
import LocaleContext from './LocaleContext'

import safetyPlusImg from '../images/services/MyHR_Safety+_one-col.png'
import recruitPlusImg from '../images/services/MyHR_Recruit+_one-col.png'
import consultPlusImg from '../images/services/MyHR_Consult+_one-col.png'
import dismissalPlusImg from '../images/services/MyHRDismissalLogoText.png'

import safetyPlusIcon from '../images/services/MyHR_Platinum_Safety-icon.png'
import recruitPlusIcon from '../images/services/MyHR_Platinum_Recruit-icon.png'
import consultPlusIcon from '../images/services/MyHR_Platinum_Consult-icon.png'
import dismissalPlusIcon from '../images/services/MyHRDismissalLogoIcon.png'
import { bookADemoLinkSwitch } from './lib/bookADemoLinkSwitch'
import BookDemoSection from './BookDemoSection'

class ExtraJS extends React.Component {
  componentDidMount() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        })
      })
    })
  }

  render() {
    return null
  }
}

class SafetyPlusForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '2e284dbe-a1bf-4102-bae4-7ad5e9777a6d',
          target: '#safetyPlusForm',
        })
      }
    })
  }

  render() {
    return (
      <div className="FormHolder">
        <div id="safetyPlusForm"></div>
      </div>
    )
  }
}

class RecruitPlusForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '44c5372f-fedc-4467-9e7b-3a93d160854d',
          target: '#recruitPlusForm',
        })
      }
    })
  }

  render() {
    return (
      <div className="FormHolder">
        <div id="recruitPlusForm"></div>
      </div>
    )
  }
}

class ConsultPlusForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '9f613e80-fae2-4259-8d95-541011c26849',
          target: '#consultPlusForm',
        })
      }
    })
  }

  render() {
    return (
      <div className="FormHolder">
        <div id="consultPlusForm"></div>
      </div>
    )
  }
}

class DismissalPlusForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '77754c02-7d96-4189-b9bb-6d79deb7fc7d',
          target: '#dismissalPlusForm',
        })
      }
    })
  }

  render() {
    return (
      <div className="FormHolder">
        <div id="consultPlusForm"></div>
      </div>
    )
  }
}

export default ({ body = null }) => {
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const toggle = e => setModal(!modal)
  const toggle2 = e => setModal2(!modal2)
  const toggle3 = e => setModal3(!modal3)
  const toggle4 = e => setModal4(!modal4)
  const locale = useContext(LocaleContext)
  const demoType = 'platinumServices'
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <section>
      <Section className="PlatinumServices py-6">
        <Container className="my-4">
          <Row>
            <Col md={6} lg={4} className="">
              <div className="PlatinumServices__item">
                <Link
                  href="#safetyplus"
                  localePrefix={false}
                  className="PlatinumServices__item-img"
                >
                  <img src={safetyPlusIcon} alt="" className="icon special" />
                </Link>
                <div>
                  <img
                    src={safetyPlusImg}
                    height="40"
                    className="title first"
                  />
                  <div>
                    MyHR Safety PLUS provides you with a detailed safety
                    system and tailored document library for a one-off, fixed
                    fee.<br /><br />
                  </div>
                  <Link
                    color="electric-blue"
                    type="button"
                    href="#safetyplus"
                    localePrefix={false}
                    className="my-4"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={6} lg={4} className="">
              <div className="PlatinumServices__item">
                <Link
                  href="#recruitplus"
                  localePrefix={false}
                  className="PlatinumServices__item-img"
                >
                  <img src={recruitPlusIcon} alt="" className="icon" />
                </Link>
                <div>
                  <img src={recruitPlusImg} height="40" className="title" />
                  <div>
                    For a simple fixed fee we can take care of your end-to-end
                    recruitment needs, from defining the job through to
                    reference checking the candidate.<br /><br />
                  </div>
                  <Link
                    color="electric-blue"
                    type="button"
                    href="#recruitplus"
                    localePrefix={false}
                    className="my-4"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>

            {locale.value == 'en-NZ' && (
            <Col md={6} lg={4} className="">
              <div className="PlatinumServices__item">
                <Link
                  href="#consultplus"
                  localePrefix={false}
                  className="PlatinumServices__item-img"
                >
                  <img src={consultPlusIcon} alt="" className="icon" />
                </Link>
                <div>
                  <img src={consultPlusImg} height="40" className="title" />
                  <div>
                    MyHR Consult PLUS gives you a dedicated HR professional to
                    help with those extra tricky issues or to deep dive into
                    complex HR needs.<br /><br />
                  </div>
                  <Link
                    color="electric-blue"
                    type="button"
                    href="#consultplus"
                    localePrefix={false}
                    className="my-4"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>
            )}

            {locale.value == 'en-AU' && (
            <Col md={6} lg={4} className="">
              <div className="PlatinumServices__item">
                <Link
                  href="#dismissalplus"
                  localePrefix={false}
                  className="PlatinumServices__item-img"
                >
                  <img src={dismissalPlusIcon} alt="" className="icon" />
                </Link>
                <div>
                  <img src={dismissalPlusImg} height="50" className="title dismissal" />
                  <div>
                    MyHR Dismissal+ gives you a dedicated employment law expert, for a 
                    simple fixed fee, to help manage an unfair dismissal claim and greatly 
                    improve your chances of a faster, more cost-effective, and less risky outcome.
                  </div>
                  <Link
                    color="electric-blue"
                    type="button"
                    href="#dismissalplus"
                    localePrefix={false}
                    className="my-4"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>
            )}

          </Row>
        </Container>
      </Section>

      <Section className="PlatinumServices__detail  bg-gray-triangle-new-2">
        <Container>

          {locale.value == 'en-NZ' && (
          <Row className="PlatinumServices__detail__row first" id="safetyplus">
            <Col sm={12} md={7} lg={7} className="PlatinumServices__left">
              <h3>MyHR Safety PLUS</h3>

              <h4>
                NZ$5,000<span>+GST</span>
              </h4>

              <p>
                A HASANZ registered Health & Safety professional will meet with
                you to discuss your business, your business risk, and any formal
                or informal systems you have in place. You’ll receive a Health &
                Safety management system, tailored to your business, including a
                complete H&S manual, policy, document procedures & templates
                (all integrated into your MyHR account), setting your business
                up to run effective, legally compliant safety processes.
              </p>
              <ul className="ticked">
                <li>HASANZ registered</li>
                <li>Certified workplace drug screener</li>
              </ul>
              <Link
                color="radical-red"
                type="button"
                localePrefix={false}
                className="my-4 d-none d-sm-block"
                onClick={toggle}
              >
                Enquire
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5} className="">
              <div className="PlatinumServices__rightBox">
                <p>Safety PLUS Service includes:</p>
                <ul>
                  <li>
                    <h6>Initial consult with a HASANZ registered expert</h6>
                  </li>
                  <li>
                    <h6>Site assessment</h6>
                  </li>
                  <li>
                    <h6>Health & Safety planning and communication</h6>
                  </li>
                  <li>
                    <h6>Risk management</h6>
                  </li>
                  <li>
                    <h6>Legal guidance</h6>
                  </li>
                  <li>
                    <h6>Health & Safety culture building</h6>
                  </li>
                  <li>
                    <h6>Policy and document creation, including:</h6>
                    <span>Planning Procedure</span>
                    <br />
                    <span>Compliance List Template</span>
                    <br />
                    <span>Training and Induction Procedure</span>
                    <br />
                    <span>Hazard Identification/Risk Management Procedure</span>
                    <br />
                    <span>Incident Management Procedure</span>
                    <br />
                    <span>Contractor Management Procedure</span>
                    <br />
                    <span>Emergency Management Plan</span>
                    <br />
                    <span>Worker Engagement and Participation Procedure</span>
                    <br />
                    <span>Review, Measurement and Evaluation Procedure</span>
                  </li>
                </ul>
              </div>
              <Link
                color="radical-red"
                type="button"
                localePrefix={false}
                className="my-4 d-sm-none"
                onClick={toggle}
              >
                Enquire
              </Link>
            </Col>
          </Row>
          )}

          {locale.value == 'en-AU' && (
          <Row className="PlatinumServices__detail__row first" id="safetyplus">
            <Col sm={12} md={7} lg={7} className="PlatinumServices__left">
              <h3>MyHR Safety PLUS</h3>
              
              <h4>
                AU$5,000<span>+GST</span>
              </h4>

              <p>
                A Health & Safety professional with regulatory and industry experience 
                will meet with you to discuss your business, your business risk profile, 
                and any formal or informal systems you have in place. You’ll receive a 
                Health & Safety management system, tailored to your business, including 
                a complete H&S manual, policy, procedures & templates (all integrated 
                  into your MyHR account), setting your business up to run effective, 
                legally compliant safety processes.
              </p>
              <Link
                color="radical-red"
                type="button"
                localePrefix={false}
                className="my-4 d-none d-sm-block"
                onClick={toggle}
              >
                Enquire
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5} className="">
              <div className="PlatinumServices__rightBox">
                <p>Safety PLUS Service includes:</p>
                <ul>
                  <li>
                    <h6>Initial consultation, followed by a handover and follow-up meeting</h6>
                  </li>
                  <li>
                    <h6>Health & Safety planning and communication strategies</h6>
                  </li>
                  <li>
                    <h6>Risk management</h6>
                  </li>
                  <li>
                    <h6>Legal guidance</h6>
                  </li>
                  <li>
                    <h6>Health & Safety culture building</h6>
                  </li>
                  <li>
                    <h6>Policy and document creation, including:</h6>
                    <span>Business planning processes</span><br />
                    <span>Objectives and Targets Procedure</span><br />
                    <span>Legal and Other Requirements Procedure</span><br />
                    <span>Training, Competency and Induction Procedure</span><br />
                    <span>Hazard Identification and Risk Management Procedure</span><br />
                    <span>Communication, Consultation and Participation Procedure</span><br />
                    <span> Incident Management Procedure</span><br />
                    <span>Corrective Action Procedure</span><br />
                    <span> Contractor Management Procedure</span><br />
                    <span>Emergency Management Procedure</span><br />
                    <span>Management Review Procedure</span><br />
                  </li>
                  <li>Additional management plans as needed, e.g., COVID-19 Safety Management</li>
                </ul>
              </div>
              <Link
                color="radical-red"
                type="button"
                localePrefix={false}
                className="my-4 d-sm-none"
                onClick={toggle}
              >
                Enquire
              </Link>
            </Col>
          </Row>
          )}

          <Row className="PlatinumServices__detail__row" id="recruitplus">
            <Col sm={12} md={7} lg={7} className="PlatinumServices__left">
              <h3>MyHR Recruit PLUS</h3>

              {locale.value == 'en-NZ' && (
              <h4>
                NZ$3,500<span>+GST</span>
              </h4>
              )}

              {locale.value == 'en-AU' && (
              <h4>
                AU$3,500<span>+GST</span>
              </h4>
              )}

              <p>
                Recruitment campaigns are hard work. Too often businesses lose
                good candidates by not addressing the market properly, or
                failing to respond in time. For a simple fixed fee we can take
                care of your end-to-end recruitment needs, from defining the job
                through to reference checking the candidate. You will have a
                dedicated, professional recruitment consultant who takes care of
                all the hassle, giving you the freedom to focus on interviewing
                the best candidates, quickly.
              </p>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle2}
                localePrefix={false}
                className="my-4 d-none d-sm-block"
              >
                Enquire
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5} className="">
              <div className="PlatinumServices__rightBox">
                <p>Recruit PLUS Service includes:</p>
                <ul>
                  <li>
                    <h6>End-to-end recruitment service</h6>
                  </li>
                  <li>
                    <h6>Job descriptions</h6>
                  </li>
                  <li>
                    <h6>Ad placement</h6>
                  </li>
                  <li>
                    <h6>Applicant screening</h6>
                  </li>
                  <li>
                    <h6>CV selection</h6>
                  </li>
                  <li>
                    <h6>Reference checks</h6>
                  </li>
                  <li>
                    <h6>No commissions or unexpected costs</h6>
                  </li>
                </ul>
              </div>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle2}
                localePrefix={false}
                className="my-4 d-sm-none"
              >
                Enquire
              </Link>
            </Col>
          </Row>

          {locale.value == 'en-NZ' && (
          <Row className="PlatinumServices__detail__row last" id="consultplus">
            <Col sm={12} md={7} lg={7} className="PlatinumServices__left">
              <h3>MyHR Consult PLUS</h3>
              <h4>POA</h4>
              <p>
                MyHR Consult PLUS gives you a dedicated HR professional to help
                with those extra tricky issues or to deep dive into complex HR
                needs. A team of qualified, experienced HR consultants is
                available when you need something extra. This service is
                available exclusively to MyHR members. Support can include small
                or large projects, consultation meetings, and you can even
                request a MyHR team-member to be seconded into your business for
                a fixed period of time to help with significant HR needs.
              </p>
              <p>
                This is a bespoke, tailored HR consultancy service, charged
                either at an hourly rate, or a fixed project fee, both
                discounted for our clients.
              </p>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle3}
                localePrefix={false}
                className="my-4 d-none d-sm-block"
              >
                Enquire
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5} className="">
              <div className="PlatinumServices__rightBox">
                <p>Consult PLUS services can include:</p>
                <ul>
                  <li>
                    <h6>Attendance at disciplinary or restructure meetings</h6>
                  </li>
                  <li>
                    <h6>Employment due diligence when buying a new business</h6>
                  </li>
                  <li>
                    <h6>Personal grievance responses</h6>
                  </li>
                  <li>
                    <h6>Mediation attendance</h6>
                  </li>
                  <li>
                    <h6>Organisational redesign</h6>
                  </li>
                  <li>
                    <h6>People strategy</h6>
                  </li>
                  <li>
                    <h6>
                      Detailed market remuneration benchmarking and job sizing
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Or any other consulting need you may have — ask our team
                      and we can help
                    </h6>
                  </li>
                </ul>
              </div>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle3}
                localePrefix={false}
                className="my-4 d-sm-none"
              >
                Enquire
              </Link>
            </Col>
          </Row>
          )}

          {locale.value == 'en-AU' && (
          <Row className="PlatinumServices__detail__row last" id="dismissalplus">
            <Col sm={12} md={7} lg={7} className="PlatinumServices__left">
              <h3>MyHR Dismissal PLUS</h3>
              <h4>AU$1,500+GST</h4>
              <p>
                When you receive an unfair dismissal claim, a MyHR employment law and conciliation 
                expert will meet with you to fully understand the situation and develop an effective 
                strategy for positively resolving the claim. We work closely with you to achieve the 
                best possible outcome for the lowest cost and risk exposure. 
              </p>
              <p>We will:
                <ul>
                  <li>Focus on the facts.</li>
                  <li>Remove the unnecessary emotion.</li>
                  <li>Prepare a submission that addresses the criteria under SECT 387.</li>
                  <li>Ensure you deal with the employee fairly and equitably.</li>
                </ul>
              </p>
              <p>
                In the event the matter cannot be resolved and goes to arbitration, we make sure you 
                begin from a position of strength to both defend and win. MyHR will have all the 
                details of the process captured, minimising risk and legal costs.

              </p>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle3}
                localePrefix={false}
                className="my-4 d-none d-sm-block"
              >
                Enquire
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5} className="">
              <div className="PlatinumServices__rightBox">
                <p>Dismissal PLUS Service includes:</p>
                <ul>
                  <li><h6>Responding to Employee F2 Unfair Dismissal Claim.</h6></li>
                  <li><h6>Preparing you for the Fair Work Commission (FWC) conciliation conference.</h6></li>
                  <li><h6>Representing and managing the FWC conciliation conference.</h6></li>
                  <li><h6>Finalising a deed of release and obtaining an F50 notice of discontinuance.</h6></li>
                </ul>
              </div>
              <Link
                color="radical-red"
                type="button"
                onClick={toggle4}
                localePrefix={false}
                className="my-4 d-sm-none"
              >
                Enquire
              </Link>
            </Col>
          </Row>
          )}
        </Container>
      </Section>

      <BookDemoSection
        title="Get started"
        text={(
          <p>
            Ready to learn more about how your business can benefit from our MyHR platinum services?
          </p>
        )}
        showBookButton={false}
        showTrialButton={false}
        extraButtons={(
          <Button
            color="electric-blue"
            href={`${demoLink}?type=${demoType}`}
          >
            Book a call
          </Button>
        )}
      />

      <Modal isOpen={modal} toggle={toggle}>
        <SafetyPlusForm />
      </Modal>

      <Modal isOpen={modal2} toggle={toggle2}>
        <RecruitPlusForm />
      </Modal>

      <Modal isOpen={modal3} toggle={toggle3}>
        <ConsultPlusForm />
      </Modal>

      <Modal isOpen={modal4} toggle={toggle4}>
        <DismissalPlusForm />
      </Modal>

      <ExtraJS />
    </section>
  )
}
