import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import RichText from './RichText'

import './InTheNewsSection.scss'


export default ({
  body = null,
  items = []
}) =>
  <Section className="InTheNewsSection">
    <Container>
      {body && body.json &&
        <div className="InTheNewsSection__intro">
          <Row>
            <Col lg={7}>
              <RichText json={body.json} />
            </Col>
          </Row>
        </div>
      }
      <Row>
        {items && items.map((item, index) => (
          <Col className="InTheNewsSection__col" sm={6} lg={4} key={index} >
            <div className="InTheNewsSection__item">
              <div className="InTheNewsSection__item-title">{item.title}</div>
              <div className="InTheNewsSection__item-date">{item.date}</div>
              {item.summary && item.summary.childMarkdownRemark && item.summary.childMarkdownRemark.html &&
                <div
                  className="InTheNewsSection__item-summary"
                  dangerouslySetInnerHTML={{ __html: item.summary.childMarkdownRemark.html }}
                />
              }
              {item.ctaLink && item.ctaLabel &&
                <a
                  href={item.ctaLink}
                  className="InTheNewsSection__item-link"
                  target="_blank"
                  rel="noopener"
                >
                  {item.ctaLabel} »
                </a>
              }
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </Section>


export const fragment = graphql`
  fragment ContentfulInTheNewsSectionFragment on ContentfulInTheNewsSection {
    body {
      json
    }
    items {
      title
      date(formatString: "DD MMM YYYY")
      summary {
        childMarkdownRemark {
          html
        }
      }
      ctaLabel
      ctaLink
    }
  }
`