import React from 'react'
import { graphql } from 'gatsby'

import Layout from './Layout'
import PageSection from './PageSection'
import SideBanner from './SideBanner'

const Page = ({ locale, children, data }) => {
  const isHomePage = data.url === '/nz/' || data.url === '/au/'
  const isFeaturesPage = data.url === '/nz/features' || data.url === '/au/features'
  return (
    <Layout
      className={data.className}
      locale={locale}
      title={data.seoMetadata && data.seoMetadata.title}
      description={data.seoMetadata && data.seoMetadata.description}
      image={data.seoMetadata && data.seoMetadata.image && data.seoMetadata.image.file.url}
      isHomePage={isHomePage}
      alternateLinks={
        data.alternateLinks && [
          {
            hrefLang: 'en-NZ',
            path: `/nz${data.url.substring(3)}`,
          },
          {
            hrefLang: 'en-AU',
            path: `/au${data.url.substring(3)}`,
          },
        ]
      }
    >
      {data.sections &&
        data.sections.map(({ id, internal, ...data }, index) => (
          <PageSection id={id} type={internal.type} data={data} key={index} />
        ))}
      {children}
    </Layout>
  )
}
export default Page

export const fragment = graphql`
  fragment ContentfulPageFragment on ContentfulPage {
    url
    alternateLinks
    seoMetadata {
      title
      description
      image {
        file {
          url
        }
      }
    }
    className
    sections {
      ... on Node {
        id
        internal {
          type
        }
      }
      ...ContentfulHeroFragment
      ...ContentfulTeamSectionFragment
      ...ContentfulContentSectionFragment
      ...ContentfulPartnersSectionFragment
      ...ContentfulTestimonialsSectionFragment
      ...ContentfulMoreCustomersSectionFragment
      ...ContentfulCustomerStoriesSectionFragment
      ...ContentfulStatsSectionFragment
      ...ContentfulWhitePaperListingSectionFragment
      ...ContentfulTrialFormSectionFragment
      ...ContentfulInTheNewsSectionFragment
      ...ContentfulJobOpeningsSectionFragment
      ... on ContentfulStaticSection {
        sectionType,
        heroItem {
          ...ContentfulLandingPageFragment 
        }
      }
    }
  }
`