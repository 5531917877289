import React from 'react'

import Hero from './Hero'
import PartnersSection from './PartnersSection'

import TeamSection from './TeamSection'
import FeatureSection from './FeatureSection'
import ContentSection from './ContentSection'
import TestimonialsSection from './TestimonialsSection'
import MoreCustomersSection from './MoreCustomersSection'
import CustomerStoriesSection, { customerStoriesSectionMapping } from './CustomerStoriesSection'
import StatsSection from './StatsSection'
import StatsSectionHome from './StatsSectionHome'
import FeaturesSection from './FeaturesSection'
import WhitePaperListingSection from './WhitePaperListingSection'
import TrialFormSection from './TrialFormSection'
import InTheNewsSection from './InTheNewsSection'
import JobOpeningsSection from './JobOpeningsSection'

// Static Sections
import BookDemoSection from './BookDemoSection'
import SubscribeSection from './SubscribeSection'
import PricingWidgetSection from './PricingWidgetSection'
import PricingSectionPricingPage from './PricingSectionPricingPage'
import ResourceListingSection from './ResourceListingSection'
import HomeInternalNav from './HomeInternalNav'
import LatestResourcesSection from './LatestResourcesSection'
import UntangleHrSection from './UntangleHrSection'
import CareersBannerSection from './CareersBannerSection'
import BoxedCTASection from './BoxedCTASection'
import MediaEnquirySection from './MediaEnquirySection'
import PopupCTA from './PopupCTA'
import ContactFormSection from './ContactFormSection'
import PlatinumServices from './PlatinumServices'
import WhySection from './WhySection'
import CareersSection from './CareersSection'
import CommunityForm from './CommunityForm'

const StaticSection = ({ sectionType, heroItem }) => {

  if (sectionType === 'BookDemoSection')
    return <BookDemoSection/>
    
  if (sectionType === 'SubscribeSection')
    return <SubscribeSection/>

  if (sectionType === 'PricingWidgetSection.dark')
    return <PricingWidgetSection theme="dark" />

  if (sectionType === 'PricingWidgetSection.light')
    return <PricingWidgetSection theme="light" />

  if (sectionType === 'PricingSectionPricingPage.dark')
    return <PricingSectionPricingPage theme="dark" />

  if (sectionType === 'PricingSectionPricingPage.light')
    return <PricingSectionPricingPage theme="light" />

  if (sectionType === 'ResourceListingSection')
    return <ResourceListingSection heroItem={heroItem} />

  if (sectionType === 'HomeInternalNav')
    return <HomeInternalNav />

  if (sectionType === 'LatestResourcesSection')
    return <LatestResourcesSection />

  if (sectionType === 'UntangleHrSection')
    return <UntangleHrSection />
  
  if (sectionType === 'CareersBannerSection')
    return <CareersBannerSection />

  if (sectionType === 'BoxedCTASection')
    return <BoxedCTASection />

  if (sectionType === 'PopupCTA')
    return <PopupCTA/>

  if (sectionType === 'MediaEnquirySection')
    return <MediaEnquirySection/>

  if (sectionType === 'ContactFormSection')
    return <ContactFormSection/>

  if (sectionType === 'PlatinumServices')
    return <PlatinumServices/>

  if (sectionType === 'WhySection')
    return <WhySection/>

  if (sectionType === 'CareersSection')
    return <CareersSection/>

  if (sectionType === 'CommunityForm')
    return <CommunityForm />
    
  return null
}



const COMPONENTS = {
  'ContentfulHero': {
    Component: Hero,
    mapping: null
  },
  'ContentfulPartnersSection': {
    Component: PartnersSection,
    mapping: null
  },
  'ContentfulTeamSection': {
    Component: TeamSection,
    mapping: null
  },
  'ContentfulFeatureSection': {
    Component: FeatureSection,
    mapping: null,
  },
  'ContentfulContentSection': {
    Component: ContentSection,
    mapping: null
  },
  'ContentfulTestimonialsSection': {
    Component: TestimonialsSection,
    mapping: null
  },
  'ContentfulMoreCustomersSection': {
    Component: MoreCustomersSection,
    mapping: null
  },
  'ContentfulCustomerStoriesSection': {
    Component: CustomerStoriesSection,
    mapping: customerStoriesSectionMapping
  },
  'ContentfulStatsSection': {
    Component: StatsSection,
    mapping: null
  },
  'ContentfulStatsSectionHome': {
    Component: StatsSectionHome,
    mapping: null
  },
  'ContentfulFeaturesSection': {
    Component: FeaturesSection,
    mapping: null
  },
  'ContentfulWhitePaperListingSection': {
    Component: WhitePaperListingSection,
    mapping: null
  },
  'ContentfulStaticSection': {
    Component: StaticSection,
    mapping: null
  },
  'ContentfulTrialFormSection': {
    Component: TrialFormSection,
    mapping: null
  },
  'ContentfulInTheNewsSection': {
    Component: InTheNewsSection,
    mapping: null
  },
  'ContentfulJobOpeningsSection': {
    Component: JobOpeningsSection,
    mapping: null
  },
}

export default ({ id, type, data }) => {
  if (!id || !type || !COMPONENTS[type]) return null  
  const { Component, mapping } = COMPONENTS[type]

  if (!Component) return null

  const  componentData = (mapping)
    ? mapping(data)
    : data

  return <Component {...componentData} />
}
