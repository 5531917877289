import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'rc-slider'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from './Link'
import LocaleContext from './LocaleContext'
import ModalVideo from './ModalVideo'
import Section from './Section'

import './CareersSection.scss'

import image1 from '../images/careers/MyHR_Our_People.png'
import image2 from '../images/careers/MyHR_Our_Benefits.png'
import values1 from '../images/careers/Values1.png' 
import values2 from '../images/careers/Values2.png'
import values3 from '../images/careers/Values3.png'

import imageVid from '../images/careers/imageVid.png'
const video = {
  url: 'https://www.youtube.com/embed/EmjrQSf-PW8',
  channel: 'youtube',
}

export default ({ theme = 'light' }) => {
  //const [people, setPeople] = useState(0)
  const locale = useContext(LocaleContext)

  return (
    <div className="CareersSection">
      <Section
        className={
          'bg-gray-triangle-new-3' +
          (theme !== 'dark' ? ` theme-${theme}` : '')
        }
      >
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <div className="w-100 pr-0 pr-md-6 pt-5 pb-3 text-center py-md-5 text-md-left">
                <h3 className="mb-3">Our People</h3>
                <p>
                  The key to MyHR’s success is our people. We’re a 100% Kiwi-owned biz with a 
                  wicked offering that our clients love and are growing like crazy – it’s an 
                  exciting time to join! We’re a talented bunch with a shared vision: we believe 
                  every business should have access to affordable, quality HR.
                </p>
              </div>
            </Col>
            <Col md={6} className="d-flex">
              <div className="w-100 pt-5 pb-3 text-left py-md-5">
                <img src={image1} className="" />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6} className="d-flex order-2 order-md-1">
              <div className="w-100 mt-3 py-md-5">
                <img src={image2} className="" />
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center order-1 order-md-2">
              <div className="w-100 pl-0 pl-md-6 pt-5 pb-3 text-center py-md-5 text-md-left">
                <h3 className="mb-3">Our Benefits</h3>
                <ul>
                  <li>An office filled to the brim with snacks, fresh fruit and barista coffee</li>
                  <li>Remote and flexible working options</li>
                  <li>Quarterly & yearly team celebrations</li>
                  <li>Monthly social events</li>
                  <li>Home office set-up</li>
                  <li>Office Yoga</li>
                  <li>Awesome team vibes</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section
        className={
          'bg-gray-triangle-new-2-opp py-6 CareersSection__values' +
          (theme !== 'dark' ? ` theme-${theme}` : '')
        }
      >
        <Container>
          <Row>
            <Col md={12} className="d-flex text-center">
              <div className="w-100 mt-3 py-md-5">
                <h3 className="mb-3">Our Values</h3>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 d-flex justify-content-center">
            <Col md={3} className="d-flex px-4">
              <div className="w-100 mt-3 py-md-3 valuesCard">
                <img src={values1} className="" />
                <h3>Make it easy.</h3>
                <p>We take the hassle out of people management so our customers can focus on what they do best</p>
              </div>
            </Col>
            <Col md={3} className="d-flex px-4">
              <div className="w-100 mt-3 py-md-3 valuesCard">
                <img src={values2} className="" />
                <h3>Own it.</h3>
                <p>We take action, hold each other accountable, and deliver what we promise.</p>
              </div>
            </Col>
            <Col md={3} className="d-flex px-4">
              <div className="w-100 mt-3 py-md-3 valuesCard">
                <img src={values3} className="" />
                <h3>Work together.</h3>
                <p>It's a team effort; we communicate, collaborate, and support each other.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="BannerSection py-5">
        <Container>
          <Row className="my-5">
            <Col
              md={{ size: 12, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                <div>
                  <div className="d-block d-md-none my-3">
                    <ModalVideo {...video}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVid} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </div>
                  <p className="my-4 vid-quote-text">
                    "You really get to feel like you're providing value, which is really cool"
                  </p>
                  <div className="customerStoryBy">
                    <p>
                      <strong>Linley Baxter</strong>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-md-block"
              md={{ size: 12 }}
              lg={{ size: 6 }}
            >
              <ModalVideo {...video}>
                {({ toggle }) => (
                  <div className="UntangleHrSection__video" onClick={toggle}>
                    <img src={imageVid} alt={'Video Thumbnail'} />
                    <div className="UntangleHrSection__video-play-icon">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="icon play-icon"
                      />
                    </div>
                  </div>
                )}
              </ModalVideo>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  )
}
