import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'rc-slider'

import Link from './Link'
import LocaleContext from './LocaleContext'

import './WhySection.scss'

import imgReducedCosts from '../images/why/MyHR_WhyReduced Costs.png'
import imgScaleEasily from '../images/why/MyHR_WhyScale Easily.png'
import imgFastDeployment from '../images/why/MyHR_WhyFast Deployment.png'
import imgSpecialistKnowledge from '../images/why/MyHR_WhySpecialist Knowledge.png'
import imgBetterTechnology from '../images/why/MyHR_WhyBetter Technology.png'
import myHr from '../images/why/MyHR.png'
import { bookADemoLinkSwitch } from './lib/bookADemoLinkSwitch'

export default ({ theme = 'light' }) => {
  const [people, setPeople] = useState(0)
  const locale = useContext(LocaleContext)
  const demoLink = bookADemoLinkSwitch(locale.value)

  /**
   * Returns the cost for in-house HR support for the given number of people,
   * as per a cost calculator spreadsheet that guesstimates it.
   *
   * NOTE: because the slider has to have an even step but we wanted to show
   * 10, 25, 50, 75 etc, we are returning the cost for 10 people when people === 0
   * and then just making the UI show 10 instead of 0.
   *
   */
  const calculateCost1 = () => {
    let cost = 12125
    if (people === 0) {
      cost = 12125
    }
    if (people === 25) {
      cost = 17900
    }
    if (people === 50) {
      cost = 40750
    }
    if (people === 75) {
      cost = 57650
    }
    if (people === 100) {
      cost = 114000
    }
    if (people === 125) {
      cost = 131875
    }
    if (people === 150) {
      cost = 150750
    }
    if (people === 175) {
      cost = 170625
    }
    if (people === 200) {
      cost = 190500
    }
    if (people === 225) {
      cost = 199500
    }
    if (people === 250) {
      cost = 202500
    }
    if (people === 275) {
      cost = 214500
    }
    if (people === 300) {
      cost = 222500
    }
    if (people === 325) {
      cost = 225500
    }
    if (people === 350) {
      cost = 284450
    }
    return cost
  }

  /**
   * Returns the real cost of MyHR subscription using the new packages
   * for the given number of people.
   *
   * NOTE: because the slider has to have an even step but we wanted to show
   * 10, 25, 50, 75 etc, we are returning the cost for 10 people when people === 0
   * and then just making the UI show 10 instead of 0.
   *
   */
  const calculateCost2 = () => {
    let cost = 3000
    if (people === 0) {
      cost = 3000
    }
    if (people === 25) {
      cost = 6480
    }
    if (people === 50) {
      cost = 11880
    }
    if (people === 75) {
      cost = 17280
    }
    if (people === 100) {
      cost = 22680
    }
    if (people === 125) {
      cost = 27480
    }
    if (people === 150) {
      cost = 32280
    }
    if (people === 175) {
      cost = 37080
    }
    if (people === 200) {
      cost = 41880
    }
    if (people === 225) {
      cost = 46680
    }
    if (people === 250) {
      cost = 51480
    }
    if (people === 275) {
      cost = 55980
    }
    if (people === 300) {
      cost = 60480
    }
    if (people === 325) {
      cost = 64980
    }
    if (people === 350) {
      cost = 69480
    }
    return cost
  }

  const BookADemoButton = (
    <Link
      color="electric-blue"
      type="button"
      href={demoLink}
      localePrefix={false}
      className="mr-4"
    >
      Book A Demo
    </Link>
  )

  const FreeTrialButton = (
    <Link color="gray" type="button" href="/trial" localePrefix={true}>
      Free Trial
    </Link>
  )

  const PeopleText = () => {
    if (people === 0) return '10 people'
    return `${people} people`
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return (
    <div>
      <section
        className={
          'WhySection bg-gray-triangle-new-3' +
          (theme !== 'dark' ? ` theme-${theme}` : '')
        }
      >
        <Container>
          <Row>
            <Col md={6} className="d-flex">
              <div className="WhySection__widget w-100 mt-3 py-md-5">
                <h3>Why MyHR?</h3>
                <p>No one likes HR — we get it.</p>
                <p>
                  That’s why we started MyHR — we wanted to make HR easy,
                  affordable and accessible for SMBs that have either never had
                  professional HR support, have paid too much, or are simply
                  suffering under the mountain of paperwork, process and
                  compliance required to manage employees whilst running their
                  business.
                </p>

                <div className="WhySection__buttons">
                  <hr className="my-5" />

                  <h6 className="mb-4">Ready to make HR easy?</h6>

                  {BookADemoButton}

                  {FreeTrialButton}

                  <p>&nbsp;</p>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex">
              <div className="WhySection__text w-100 pt-5 pb-3 text-left py-md-5">
                <div className="WhySection__rightBox">
                  <Row>
                    <Col md={12} lg={6} className="">
                      <h4>
                        5 Good Reasons
                        <br />
                        to Outsource HR
                      </h4>
                      <hr className="my-4" />

                      <div className="item pt-2">
                        <div className="img">
                          <img src={imgReducedCosts} className="" />
                        </div>
                        <div className="content">
                          <h6>Reduced costs</h6>
                          <p>
                            No individual software licensing fees, consultancy
                            fees or salaries.
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img src={imgScaleEasily} className="" />
                        </div>
                        <div className="content">
                          <h6>Scale Easily</h6>
                          <p>
                            Tap in to the resource you need and access more as
                            you grow.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} lg={6} className="">
                      <div className="item">
                        <div className="img">
                          <img src={imgFastDeployment} className="" />
                        </div>
                        <div className="content">
                          <h6>Fast deployment</h6>
                          <p>
                            Outsourced HR and tech can be easily rolled out in a
                            single process.
                          </p>
                        </div>
                      </div>

                      <div className="item">
                        <div className="img">
                          <img src={imgSpecialistKnowledge} className="" />
                        </div>
                        <div className="content">
                          <h6>Specialist knowledge</h6>
                          <p>
                            Work with a team of HR experts with a wide range of
                            capabilities.
                          </p>
                        </div>
                      </div>

                      <div className="item">
                        <div className="img">
                          <img src={imgBetterTechnology} className="" />
                        </div>
                        <div className="content">
                          <h6>Better technology</h6>
                          <p>
                            Tech that keeps you up to date with the latest HR
                            developments.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6} className="d-flex">
              <div
                className="WhySection__widget w-100 mt-3 py-md-5"
                id="Calculator"
              >
                <h3>Why we're different</h3>
                <p>
                  No one does HR like MyHR. Our combination of an on-call HR
                  advisory team and easy-to-use HR software is unlike any other,
                  providing a singular, outsourced HR platform that's built for
                  your business and accessible whenever and wherever you need
                  it.
                </p>

                <p>
                  All MyHR documentation is fit-for-purpose, written for you, checked 
                  by a team of experts and delivered via our custom built software
                  which allows you to run performance reviews, log leave, e-sign
                  {locale.value == 'en-NZ' ? ' agreements' : ' contracts'}, run
                  reports and plenty more.
                </p>

                <p>
                  Many HR software companies offer an HRIS as a subscription
                  service as do some HR consultancies and employer associations
                  who offer on-call advice and document plans. MyHR members get
                  both, all for a fixed monthly fee, calculated on how many
                  employees they have.
                </p>

                <p>
                  MyHR membership delivers a full, wraparound HR service that’s
                  typically 30% of comparable cost of an internal resource
                  running a basic HRIS. We believe that MyHR is the most
                  comprehensive HR offer in the market today, and for most SMBs
                  it’s all the HR they’ll ever need.
                </p>
              </div>
            </Col>
            <Col md={6} className="d-flex">
              <div className="WhySection__text w-100 pt-5 pb-3 text-center py-md-5 text-md-left">
                <div className="WhySection__rightBox">
                  <div className="text-left text-sm-center">
                    <h4>HR Cost Calculator</h4>
                    <p>
                      Drag the slider below to see how much MyHR could be saving
                      you each year.
                    </p>
                    <h6>
                      <strong>How many people are in your company?</strong>
                    </h6>
                    <span className="people text-center">
                      {people === 0 ? 10 : people}
                    </span>
                    <Slider
                      min={0}
                      step={25}
                      max={350}
                      defaultValue={people}
                      onChange={val => setPeople(val)}
                    />
                  </div>

                  <hr className="separator" />

                  <div className="results">
                    <Row>
                      <Col
                        md={12}
                        lg={6}
                        className="rightBorder text-center px-5"
                      >
                        <h6>Typical HR cost for a team of {PeopleText()}*</h6>

                        <div className="bars d-flex flex-row">
                          <div className="left d-flex flex-column justify-content-end align-items-center">
                            <span className="smallPrice">
                              {formatter.format(calculateCost1())}
                            </span>
                            <div className="bar"></div>
                            <span className="barTitle">IN-HOUSE HR</span>
                          </div>
                          <div className="right d-flex flex-column justify-content-end align-items-center">
                            <span className="smallPrice">
                              {formatter.format(calculateCost2())}
                            </span>
                            <div className="bar"></div>
                            <span className="barTitle">
                              <img src={myHr} className="" />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} lg={6} className="text-center px-5">
                        <h6>
                          Potential annual savings when you switch to MyHR*
                        </h6>
                        <span className="price">
                          {formatter.format(
                            calculateCost1() - calculateCost2()
                          )}
                        </span>
                      </Col>
                    </Row>
                  </div>

                  <div className="conditions text-left">
                    * Our cost-savings calculator uses
                    internationally-recognised HR staff ratios and software and
                    consultancy charges to work out average HR costs that are
                    then scaled up or down, depending on the size of your
                    organisation. The costs are averages and intended to provide
                    a guide to what a more traditional HR model would cost your
                    business, compared to MyHR's complete outsourced solution.
                    The calculator will not be 100% applicable to every New
                    Zealand or Australian business or every employment
                    situation.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="BannerSection py-5">
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 3 }}>
              <div className="h-100 d-flex">
                <div className="BannerSection__text m-auto">
                  <h2 className="h1 mb-2">Stay compliant. Stay in control.</h2>
                  <p className="my-4">
                    MyHR members gain the confidence and clarity to make the
                    hard calls when required, stay compliant in their employee
                    processes and, most importantly, stay in control of their
                    employee relationships. Create the space to focus on what
                    you do best - your business.
                  </p>
                  <Link
                    type="button"
                    color="cyan"
                    href="/trial"
                    localePrefix={true}
                  >
                    Start Free Trial
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
