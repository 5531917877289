import React from 'react'
import Link from '../components/Link'

// Set APP_MAINTENANCE_MODE to true to stop trial form submissions
const APP_MAINTENANCE_MODE = false

const MaintenanceMessage = () => (
  <div style={{ minHeight: '340px' }}>
    <p>MyHR is currently doing some important scheduled maintenance. </p>

    <p>
      Please use the{' '}
      <Link href="/contact" localePrefix={true}>
        contact form
      </Link>{' '}
      to leave us your details and we will follow up with you on Monday.
    </p>
  </div>
)

export const useAppMaintenanceMode = () => {
  return [APP_MAINTENANCE_MODE, MaintenanceMessage()]
}
